import React from 'react';
import { idp_home_icons, idp_tam_icons } from '../idpIcons';
import '../css/idpDetail.css';
import { Button, Modal, Spin, DatePicker, Select, Tooltip } from 'antd'
import IDPApproveContainer from '../containers/idpApproveContainer';
import IDPSuggessionandRejectContainer from '../containers/idpSuggesionandRejectContainer';
import { ReactMarkdown, displayUtcToUserTimezone } from 'modules/look';
import { idp_status } from '../idp.config';
import moment from 'moment-timezone';
import drop_icon from '../../assets/idb-dropdown-arrow.svg' 
const IDPDetailView = (props) => {
    const { idp_detail, idp_detail_loading, me, public_manager, OnSubmitIDP, navigateRoute,permission } = props
    const [approve_idp, setApproveIdp] = React.useState(false)
    const [suggestion_idp, setSuggestionIdp] = React.useState(false)
    const [idp_form, SetIDPForm] = React.useState()
    const Option = Select.Option
    React.useEffect(() => {
        if (idp_detail) {
            SetIDPForm(null)
            let resources = document.getElementById('idp-resource')
            if (resources) {
                resources.innerHTML = idp_detail?.resources?.split('\n')?.join("<br>")
            }
            let activity = document.getElementById('idp-activity')
            if (activity) {
                activity.innerHTML = idp_detail?.activity?.split('\n')?.join("<br>")
            }
        }

    }, [idp_detail])

    const getColor = (status) => {
        if (status === idp_status?.ToDo?.for_query) {
            return "rgba(76, 203, 31, 1)"
        } else if (status === idp_status?.Approved?.for_query) {
            return "rgba(76, 203, 31, 1)"
        } else if (status === idp_status?.InProgress?.for_query) {
            return "rgba(76, 203, 31, 1)"
        } else if (status === idp_status?.Pedning_Approval?.for_query) {
            return "rgba(249, 95, 83, 1)"
        } else if (status === idp_status?.Completed?.for_query) {
            return "rgba(76, 203, 31, 1)"
        } else if (status === idp_status?.Revise?.for_query) {
            return "rgba(217, 176, 59, 1)"
        } else if (status === idp_status?.Draft?.for_query) {
            return "rgba(0, 0, 0, 1)"
        }
    }

    const updateForm = (data) => {
        let form = idp_form ? { ...idp_form } : {}
        if (data?.label == "duedate") {
            data.value = moment(data?.value)?.endOf('day').utc().toISOString()
            form["status"] = idp_status?.Pedning_Approval.for_mutation
        }
        form[data?.label] = data?.value
        SetIDPForm(form)
    }



    const getStatus = (is_completetion_status) => {
        let status
        let approved_condition = [
            idp_status.Approved.for_query,
            idp_status.ToDo.for_query,
            idp_status.InProgress.for_query,
            idp_status.Completed.for_query
        ]
        if (me?.employee?.id != idp_detail?.employee?.id || public_manager) {
            if (idp_detail?.status === idp_status.Pedning_Approval.for_query) {
                status = is_completetion_status ? idp_status?.ToDo?.for_query : 'Pending'
            }
            else if (approved_condition?.includes(idp_detail?.status)) {
                status = is_completetion_status ? idp_detail?.status : 'Approved'
            }
            else {
                status = idp_detail?.status
            }
        }
        else if (me?.employee?.id == idp_detail?.employee?.id) {
            if (idp_detail?.status === idp_status.Pedning_Approval.for_query) {
                status = is_completetion_status ? idp_status?.ToDo?.for_query : 'Pending'
            }
            else if (approved_condition?.includes(idp_detail?.status)) {
                status = is_completetion_status ? idp_detail?.status : 'Approved'
            }
            else {
                status = idp_detail?.status
            }
        }
        if (status == idp_status.ToDo.for_query) {
            status = "TO DO"
        }
        else if (status == idp_status.InProgress.for_query) {
            status = "IN PROGRESS"
        }
        return status
    }

    const canChangeStatus = [
        idp_status.Approved.for_query,
        idp_status.ToDo.for_query,
        idp_status.InProgress.for_query
    ]

    let status_list = [
        {
            key: idp_status?.Approved?.for_mutation,
            label: idp_status?.Approved?.label
        },
        {
            key: idp_status?.ToDo?.label,
            label: idp_status?.ToDo?.label
        },
        {
            key: idp_status?.InProgress?.label,
            label: idp_status?.InProgress?.label
        },
        {
            key: idp_status?.Completed?.for_mutation,
            label: idp_status?.Completed?.label
        }
    ]

    return (
        <Spin size='large' spinning={idp_detail_loading}> 
 
            {
                idp_detail && (
                    <div className="idp-home-root">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                            {
                                !public_manager && (
                                    <img src={idp_tam_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                                )
                            }

                            <h4 className="team-idp-main-head">{idp_detail?.goalType === "SHORT" ? "Short Term Goal" : "Long Term Goal"}</h4>
                        </div>
                        <div className="idp-home-container " style={{ marginTop: "1em" }}>

                            <div className='idp-container-wrap' style={{ width: "100%" }}>
                                <div className="idp-detail-left-container" style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "1em" }}>
                                    {
                                        me?.employee?.id != idp_detail?.employee?.id && (
                                            <h4 className="idp-detail-small-title" style={{ fontWeight: "500" }}>Employee : <span style={{ fontWeight: "600" }}>{idp_detail?.employee?.user?.firstName} {idp_detail?.employee?.user?.lastName}</span></h4>
                                        )
                                    }

                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                                        {idp_detail?.goalType == "LONG" ? <img src={idp_home_icons?.long_term_goal} alt="long_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} /> :
                                            <img src={idp_home_icons?.short_term_goal} alt="short_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} />}
                                        <h4 className="idp-detail-heading" style={{ wordBreak: "break-all" }}>{idp_detail?.goal}</h4>
                                    </div>

                                    {
                                        (me?.employee?.id == idp_detail?.employee?.id && !public_manager) && (
                                            <h4 className="idp-detail-small-title idp-text-transform detail-mobile-view" style={{ color: getColor(idp_detail?.status) }}>{getStatus(false)}</h4>
                                        )
                                    }

                                    {
                                        (me?.employee?.id != idp_detail?.employee?.id || public_manager) && (
                                            <h4 className="idp-detail-small-title idp-text-transform detail-mobile-view" style={{ color: getColor(idp_detail?.status) }}>{getStatus(false)}</h4>
                                        )
                                    }

                                    {
                                        (idp_detail?.status != idp_status?.Revise.for_query && idp_detail?.status != idp_status?.Pedning_Approval.for_query && idp_detail?.status != idp_status?.Draft.for_query) && (
                                            <div style={{ display: "flex", flexDirection: "row", justifyComtnet: "start", gap: "1em", alignItems: "center" }}>
                                                {
                                                    (me?.employee?.id != idp_detail?.employee?.id || public_manager || getStatus(true) == idp_status.Completed.for_query || getStatus(true) == idp_status.Draft.for_query || moment(idp_detail?.duedate).isSameOrBefore(moment())) && (
                                                        <h4 className="idp-detail-small-title">Completion Status : {getStatus(true)}</h4>
                                                    )
                                                }

                                                {
                                                    (me?.employee?.id == idp_detail?.employee?.id && !public_manager && canChangeStatus?.includes(idp_detail?.status) && moment(idp_detail?.duedate).isAfter(moment())) && (
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5em", width: "100%", alignItems: "center" }}>
                                                            <h4 className="idp-detail-small-title" style={{ whiteSpace: "nowrap" }}>Completion Status :</h4>
                                                            <div className="idp-select-container" style={{ margin: "0px", maxWidth: "200px" }}>
                                                                <Select
                                                                    className="idp-select idp-detail-select"
                                                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                                                                    placeholder="Select Status"
                                                                    suffixIcon={<img src={drop_icon} alt="drop_icon" />}
                                                                    showSearch={false}
                                                                    filterOption={false}
                                                                    onSelect={(e) => { updateForm({ label: 'status', value: e }) }}
                                                                    defaultValue={idp_detail?.status}
                                                                    dropdownClassName="content-dropdown"
                                                                    value={idp_form?.status || (idp_detail?.status == idp_status?.ToDo.for_query ? "To Do" : idp_detail?.status == idp_status?.InProgress.for_query ? "In Progress" : idp_detail?.status)}
                                                                >

                                                                    {
                                                                        status_list?.map(i => (
                                                                            <Option
                                                                                className='rd-search-menu-title' style={{ color: '#BBBBBB' }}
                                                                                key={i?.key}
                                                                                value={i?.key}
                                                                            >
                                                                                {i?.label}
                                                                            </Option>
                                                                        ))
                                                                    }

                                                                </Select>
                                                            </div>
                                                        </div>
                                                    )
                                                }


                                            </div>
                                        )
                                    }

                                    <div style={{ display: "flex", flexDirection: "column", justifyComtnet: "start", gap: "1em" }}>
                                        <h4 className="idp-detail-small-title">Skills</h4>
                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyComtnet: "start", gap: "1em", alignItems: "center" }}>
                                            {idp_detail?.skillSet?.map(item => (
                                                <div className='custom-dropdown-insider-ele overflow-ellipse'>
                                                    <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{item?.competency?.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                        <h4 className="idp-detail-small-title">Developmental Activities</h4>
                                        {/* <p className="idp-detail-para idp-markdown"><ReactMarkdown>{idp_detail?.activity}</ReactMarkdown></p> */}
                                        <div className="idp-detail-para idp-markdown" id='idp-activity'></div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyComtnet: "start", gap: "1em", alignItems: "center" }}>
                                        <span className="idp-detail-small-title">Next Milestone</span>
                                        <span className="idp-detail-small-title" style={{ color: "#8998A2" }}>By</span>
                                        {(moment(idp_detail?.duedate).isAfter(moment()) || idp_detail?.status == idp_status?.Draft.for_query || idp_detail?.status == idp_status?.Revise.for_query || me?.employee?.id != idp_detail?.employee?.id || public_manager) ? <span className="idp-detail-small-title">{idp_detail?.duedate ? displayUtcToUserTimezone(idp_detail?.duedate, 'DD/MM/YYYY') : "-"}</span> :
                                            <DatePicker
                                                className='idp-date-picker-custom'
                                                dropdownClassName={'generic-date-picker-date'}
                                                style={{ width: '180px', height: '3em' }}
                                                defaultValue={idp_detail ? moment(idp_detail?.duedate) : ''}
                                                format={'DD/MM/YYYY'}
                                                clearIcon={false}
                                                suffixIcon={<img src={idp_home_icons?.calender} />}
                                                disabledDate={(e) => moment(e).isSameOrBefore(moment().endOf('day'))}
                                                onChange={(e) => { updateForm({ label: 'duedate', value: e }) }}
                                                value={idp_form?.duedate ? moment(idp_form?.duedate) : moment(idp_detail?.duedate)}
                                            />}
                                    </div>
                                    <p className="idp-detail-para">{idp_detail?.milestone}</p>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                        <h4 className="idp-detail-small-title">Resources Needed</h4>
                                        <div className="idp-detail-para idp-markdown" id='idp-resource'></div>
                                        {/* <p className="idp-detail-para idp-markdown"><ReactMarkdown>{idp_detail?.resources}</ReactMarkdown></p> */}
                                    </div>
                                </div>
                                <div className="idp-detail-right-container" style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "1em" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                        <p className="idp-detail-para detail-web-view" style={{ textAlign: "left" }}>Create Date : <span style={{ fontWeight: "500" }}>{displayUtcToUserTimezone(idp_detail?.createdDt, 'DD MMM YYYY')}</span></p>
                                        {
                                            (me?.employee?.id == idp_detail?.employee?.id && !public_manager) && (
                                                <h4 className="idp-detail-small-title idp-text-transform detail-web-view" style={{ color: getColor(idp_detail?.status) }}>{getStatus(false)}</h4>
                                            )
                                        }

                                        {
                                            (me?.employee?.id != idp_detail?.employee?.id || public_manager) && (
                                                <h4 className="idp-detail-small-title idp-text-transform detail-web-view" style={{ color: getColor(idp_detail?.status) }}>{getStatus(false)}</h4>
                                            )
                                        }


                                        {
                                            (getStatus(false) == "Approved" && idp_detail?.comments) ?
                                                <div style={{ background: "#F6FBFF", borderRadius: ".5em", padding: ".5em" }}>
                                                    <p className="idp-detail-para" style={{ fontSize: ".85em", textAlign: "justify", fontWeight: 600 }}>Comment</p>
                                                    <p className="idp-detail-para" style={{ fontSize: ".85em", textAlign: "justify", marginTop: ".5em" }}>{idp_detail?.comments}</p>
                                                </div> : ''
                                        }

                                        {
                                            (idp_detail?.status == idp_status.Revise.for_query && idp_detail?.suggestion) ?
                                                <div style={{ background: "#F6FBFF", borderRadius: ".5em", padding: ".5em" }}>
                                                    <p className="idp-detail-para" style={{ fontSize: ".85em", textAlign: "justify", fontWeight: 600 }}>Suggestion</p>
                                                    <p className="idp-detail-para" style={{ fontSize: ".85em", textAlign: "justify", marginTop: ".5em" }}>{idp_detail?.suggestion}</p>
                                                </div> : ''
                                        }

                                    </div>
                                </div>
                            </div>


                            {((idp_detail?.approver?.employee?.id === me?.employee?.id || public_manager) && getStatus(false) == 'Pending') &&
                                <div style={{ display: "flex", flexDirection: "row", gap: "1em", marginTop: "2em" }}>
                                    <Button className="idp-green-btn" onClick={() => setApproveIdp(true)}>Approve</Button>
                                    <Button className="idp-green-border-btn" onClick={() => setSuggestionIdp(true)}>Give Suggestions</Button>
                                </div>}

                            {
                                (idp_detail?.employee?.id == me?.employee?.id && idp_form && !public_manager) && (
                                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", marginTop: "2em" }}>
                                        <Tooltip title={(idp_form?.duedate && getStatus(true) == 'Approved') ? 'While changing the duedate your manager has to again approve your idp' : ''}>
                                            <Button className='idp-submit idp-btn' onClick={() => { OnSubmitIDP(idp_form) }}>Save</Button>
                                        </Tooltip>
                                        <Button className='idp-cancel idp-btn' onClick={() => SetIDPForm(null)} >Cancel</Button>
                                    </div>
                                )
                            }

                        </div>


                        <Modal
                            visible={approve_idp}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            style={{ maxWidth: "40em" }}
                            className='idp-modal'
                            width={"90%"}
                        >
                            <IDPApproveContainer {...props} onCancel={() => setApproveIdp(false)} onSuccess={(res) => { OnSubmitIDP({ status: idp_status.Approved.for_mutation, comments: res, suggestion: "" }); setApproveIdp(false) }} />
                        </Modal>
                        <Modal
                            visible={suggestion_idp}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            style={{ maxWidth: "40em" }}
                            className='idp-modal'
                            width={"90%"}
                        >
                            <IDPSuggessionandRejectContainer {...props} onCancel={() => setSuggestionIdp(false)} onSuccess={(res) => { OnSubmitIDP({ status: idp_status.Revise.for_mutation, comments: "", suggestion: res }); setSuggestionIdp(false) }} />
                        </Modal>
                    </div>
                )
            }
        </Spin>
    )
}

export default IDPDetailView