import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Modal, Row, Spin, Tabs } from "antd";
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FEEDBACK_COMPRESSED_TOOL_ID, GOAL_HOME_TOOL_ID, ROLEGOAL_HOME_TOOL_ID } from '../../../config';
import FeedbackLeftWireFrame from '../../assets/feedback-left-wireframe.svg';
import FeedbackHomeImg from '../../assets/feedback-new-home.svg';
import FeedbackRightWireFrame from '../../assets/feedback-right-wireframe.svg';
import { getIntFromString, getSelectedFeedbackType } from '../../look';
import GiveKudosPopupContainer from '../containers/GiveKudosPopupContainer';
import PostFeedbackContainer from '../containers/PostFeedbackContainer';
import { replaceAnswer1, replaceAnswer2, replaceAnswer3, replaceAnswer4, replaceAnswer5Feedback1, replaceAnswer5Feedback2 } from '../containers/textReplace';
import '../css/NewFeedbackHome.css';
import FeedbackDetailView from './ViewFeedbackPopUp';

// imports for permissions 
import { feedback_permission } from 'Permissions/feedback.permission';
import SideToolFedback from '../containers/feedbackSideTool';
import feedback_route from '../route/index';
 
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
const NewFeedbackHome = props => {
  const {
    me,
    feedbackPermission,
    type,
     createFeedback, createBadgeAward,
     SetAITrigger,
    ai_trigger
  } = props;

  const antIcon = (
    <LoadingOutlined style={{ fontSize: "34px", color: "#4CCB1F" }} />
  )


  const [selectedTab, setTab] = React.useState();
  const { TabPane } = Tabs;

  const [feedbackById, setFeedBackById] = React.useState();
  const [feedbackEditModalVisible, setFeedbackEditModalVisible] = React.useState(false)

  const [givenKudos, setGivenKudos] = React.useState(undefined);
  const [badgeAwardId, setBadgeAwardId] = React.useState();
  const [giveKudosPopupVisible, setGiveKudosPopUpVisible] = React.useState(false)
  const [Recipient, setRecipient] = React.useState(undefined);
  const [recipientName, setrecipientName] = React.useState(undefined);
  const [form] = Form.useForm();
  const [okrList, setokrList] = React.useState([])
  const [okrid, setokrid] = React.useState(undefined);
  const [feedBackType, setFeedBackType] = React.useState("1");
  const [fillInOption, setfillInOption] = React.useState(false);
  const [questionList, setquestionList] = React.useState([]);
  const [answersList, setAnswers] = React.useState([]);
  const [question1Field1, setquestion1Field1] = React.useState(undefined);
  const [question1Field2, setquestion1Field2] = React.useState(undefined);
  const [question2Field1, setquestion2Field1] = React.useState(undefined);
  const [question2Field2, setquestion2Field2] = React.useState(undefined);
  const [question2Field3, setquestion2Field3] = React.useState(undefined);
  const [question3Field1, setquestion3Field1] = React.useState(undefined);
  const [question3Field2, setquestion3Field2] = React.useState(undefined);
  const [question4Field1, setquestion4Field1] = React.useState(undefined);
  const [question5Field2, setquestion5Field2] = React.useState(undefined)
  const [question5Field1, setquestion5Field1] = React.useState(undefined)
  const [navigationFromOkr, setnavigationFromOkr] = React.useState(false);
  const [hideselection, sethideselection] = React.useState(false);
  const [employeeList, setemployeeList] = React.useState([]);
  const [invalidFeedbackType, setInvalidFeedbackType] = React.useState(false);
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)
  const [selectedOkr, setSelectedOkr] = React.useState(undefined);
  const [activeTab, setActiveTab] = React.useState("received");
  const [checkBoxSelection, setCheckBoxSelection] = React.useState(undefined);
  const [newKudosAdded, setNewKudosAdded] = React.useState(false)
  const [feedbackDetails, setFeedbackDetails] = React.useState();
  const [receipientNameInvalid, setReceipientNameInvalid] = React.useState(false);
  const [submittedFeedback, setSubmittedFeedback] = React.useState(undefined)
  const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false)
  const [feedbackDetailSide,setFeedbackDetailSide] = React.useState("submitted");
  const [loading, setloading] = React.useState(false)
  const feedBackTypeEnum = {
    1:'MOTIVATIONAL_RECOGNITION',
    2:'DEVELOPMENTAL_CONSTRUCTIVE'
   }

  const [tabArray, setTabArray] = React.useState([
    { id: 0, value: 'C', active: true },
    { id: 1, value: 'L', active: true },
    { id: 2, value: 'A', active: true },
    { id: 3, value: 'P', active: true },
    { id: feedBackType == '1' ? 4 : 4, value: 'S', active: true }])

  const [tabArrayDetailed, setTabArrayDetailed] = React.useState([
    { id: 0, value: 'C', active: true },
    { id: 1, value: 'L', active: true },
    { id: 2, value: 'A', active: true },
    { id: feedBackType == '1' ? null : 3, value: 'P', active: true },
    { id: feedBackType == '1' ? 4 : 4, value: 'S', active: true }])

  const [questionSet, setQuestionSet] = React.useState({
    0: [question1Field1, question1Field2],
    1: [question2Field1, question2Field2, question2Field3],
    2: [question3Field1, question3Field2],
    3: [question4Field1],
    4: [question5Field1, question5Field2]
  })
  const ai_tip_triggered_ref = React.useRef(false)
  React.useEffect(() => {
    if(feedBackType==1){
      
      if(SetAITrigger){
        ai_tip_triggered_ref.current = true
        SetAITrigger({
          trigger:ai_trigger_content['motivational_feedback']
        })
      }
     
    }
    else if(feedBackType==2){
      if(SetAITrigger){
        ai_tip_triggered_ref.current = true
        SetAITrigger({
          trigger:ai_trigger_content['developmental_feedback']
        })
      }
    }

  },[feedBackType])
  
  React.useEffect(() =>{
    if(giveKudosPopupVisible){
      if(SetAITrigger){
        ai_tip_triggered_ref.current = true
        SetAITrigger({
          trigger:ai_trigger_content['give_kudos']
        })
      }
    }
  },[giveKudosPopupVisible])


  const history = useHistory()
  const [feedbackPreviewDisplay, setFeedbackPreviewDisplay] = React.useState(false);
  const [feedbackDetailsLoading, setFeedbackDetailsLoading] = React.useState(false)
  const [feedbackOperationsModalVisible, setFeedbackOperationsModalVisible] = React.useState(false)

  React.useEffect(() => {
    if (type) {
      setTab(type)
    }
  }, [type])

  const back = () => {
    history.goBack();
  };

  const backToRoleGoal = () => {
    history.push(`/page/role_goal/home/${ROLEGOAL_HOME_TOOL_ID}`)
  };

  const navigateTo = () => {
    if (feedback_permission) {
      history.push(feedback_route?.feedback?.replace(":type","received"))
    }
  };

  const goToGoalHome = () => {
    history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
  }

  const goHome = () => {
    history.push('/home')
  }

  let feedbackModalOpener = (e) => {
    e.preventDefault();
    setFeedbackOperationsModalVisible(true)
  }

  const resetStateOfElemets = () => {
    setRecipient()
    setrecipientName()
    setokrList([])
    setFeedBackType();
    setfillInOption(false);
    setnavigationFromOkr(false);
    setInvalidFeedbackType(false);
    setSelectedOkr();
    setCheckBoxSelection();
    setSubmittedFeedback()
    resetFeedbackForm()
    setCheckBoxSelection()

  }

  const handleKudosSubmit = async (createdFeedbackId) => {
    if (givenKudos) {
      try {
        let badgeAwardData = { badgeId: givenKudos?.badgeId, evidence: givenKudos?.evidence, relatedToId: createdFeedbackId,
           recipientId: getIntFromString(Recipient?.id),
           notify:'employee' }
        const res = await createBadgeAward({ badgeAwardData })
        if (res) {
          setFeedbackOperationsModalVisible(false)
        }
      } catch (error) {
        console.error(error);

      }
    }

  }


  async function handleSubmit(value) {
    setloading(true)
    let selectedFeedbackType = getSelectedFeedbackType(feedBackTypeEnum,feedBackType)
    let feedbackData = {
      feedbackToId: getIntFromString(Recipient?.id),
      okrId: getIntFromString(selectedOkr?.id),
      feedbackType: selectedFeedbackType,
      detailFillIn: fillInOption
    }
    const response = await createFeedback({ answers: value, feedbackData: feedbackData, surveyId: 15 });
    if (response && response?.id) {
      message.destroy()
      if (givenKudos){
      message.success('Kudos given and Feedback Submitted Successfully')

      }else {
      message.success('Feedback Submitted Successfully')

      }
        setSubmittedFeedback(response)
        handleKudosSubmit(response?.id)
        setFeedbackOperationsModalVisible(false)
        resetStateOfElemets()
        setloading(false)
        setFeedbackDetails(undefined)
        setFeedbackPreviewDisplay(false)
        setFeedbackModalVisible(false)
    } else {
      setloading(false)
    }
  }


  const handleValidationwithFillin = (submitData) => {
    if (Recipient && feedBackType) {
      let answerdata = []
      if (feedBackType === "1") {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2) {
          message.error('please fill in the required field')
          return
        }
      }
      else if (feedBackType === '2') {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2 || !question4Field1) {
          message.error('please fill in the required field')
          return
        }
      }

      questionList.forEach(question => {
        let typeValidations = question?.questionAddress?.split(':')
        if (!question?.questionAddress || typeValidations?.includes(feedBackType)) {
          let response = {
            questionId: getIntFromString(question?.id),
            answer: ''
          }
          if (getIntFromString(question?.id) === 683) {
            response.answer = replaceAnswer1(question1Field1, question1Field2)
          }
          else if (getIntFromString(question?.id) === 684) {
            response.answer = replaceAnswer2(question2Field1, question2Field2, question2Field3)
          }
          else if (getIntFromString(question?.id) === 685) {
            response.answer = replaceAnswer3(question3Field1, question3Field2)
          }
          else if (getIntFromString(question?.id) === 686) {
            response.answer = replaceAnswer4(question4Field1)
          }
          else if (getIntFromString(question?.id) === 687) {
            response.answer = feedBackType === "1" ? replaceAnswer5Feedback1(question5Field1) : feedBackType === "2" ? replaceAnswer5Feedback2(question5Field2) : ''
          }
          answerdata.push(response)
        }

      });

      if (submitData) {
        handleSubmit(answerdata)
      }

      return answerdata;

    }
    else {
      message.error('please fill in the required field')
    }
  }


  const handleValidation = () => {
    if (Recipient && feedBackType) {
        let answerdata = []


        questionList.forEach(data => {
            if (!data?.questionAddress) {
                let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
                answerdata.push(answer)
            }
            else {
                let typeValidations = data?.questionAddress?.split(':')
                if (typeValidations?.includes(feedBackType)) {
                    let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
                    answerdata.push(answer)
                }
            }
        });


        let valid = true
        questionList.forEach(data => {
            if (!data?.questionAddress) {
                if (data?.required) {
                    let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
                    if (!answer) {
                        valid = false
                    }
                }

            }
            else {
                let typeValidations = data?.questionAddress?.split(':')
                if (typeValidations?.includes(feedBackType) && data?.required) {
                    let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
                    if (!answer) {
                        valid = false
                    }
                }
            }
        });
        if (valid) {
            handleSubmit(answerdata)
        }
        else {
            message.error('please fill in the required field')
        }
    }
    else {
        setReceipientNameInvalid(true)
        message.error('please fill in the required field')
    }
}


  const resetFeedbackForm = () => {
    setquestion1Field1("");
    setquestion1Field2("");
    setquestion2Field1("");
    setquestion2Field2("");
    setquestion2Field3("");
    setquestion3Field1("");
    setquestion3Field2("");
    setquestion4Field1("");

    setTabArray([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: 3, value: 'P', active: true },
      { id: feedBackType == '1' ? 4 : 4, value: 'S', active: true }]);
    setTabArrayDetailed([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: feedBackType == '1' ? null : 3, value: 'P', active: true },
      { id: feedBackType == '1' ? 4 : 4, value: 'S', active: true }])
    setAnswers([]);
    setGivenKudos()
    setSelectedTabValue(0)
  }


  return (
    <>
   

      {/* Page Title and Back Arrow */}
      <div className="main-title-container back-arrow-global" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'relative', margin: '0em 1em', paddingBottom: '1.5em' }}>
        
        <div>
          <p className="goalheader poppins" style={{ margin: 'auto', textAlign: 'center', color: 'black', fontWeight: '700',fontSize:'1.8em' }}>Feedback</p>
          <p className='poppins'>Build Trust Through Feedback!</p>
        </div>

      </div>
      <SideToolFedback {...props} tool_id={FEEDBACK_COMPRESSED_TOOL_ID}></SideToolFedback>
      <div style={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} >
        <div className='feedback-home-btn-cont feedback-btn-container'>
          <Col align='center' style={{ cursor: 'pointer', zIndex: '5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button style={{ cursor: 'pointer', zIndex: '5' }} className='give_feedback_btna' onClick={(e) => feedbackModalOpener(e)} >GIVE FEEDBACK</button>
          </Col>
          <Col className='feedback-view-btn' align='center' style={{ cursor: 'pointer', zIndex: '5' }}>
            <CreateButtonNew style={{ cursor: 'pointer', zIndex: '5' }} onClick={navigateTo} type="text">VIEW FEEDBACK</CreateButtonNew>
          </Col>
        </div>

      </div>
      <div className='feedback-image-container'>
        <img src={FeedbackHomeImg} alt="feedback-home" className='feedback-home-image' />
        <img src={FeedbackLeftWireFrame} alt='left-wireframe' className='feedback-left-wireframe' />
        <img src={FeedbackRightWireFrame} alt='right-wireframe' className='feedback-right-wireframe' />
      </div>

      <Modal visible={feedbackOperationsModalVisible}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
        style={{minWidth:"40em"}}
      >
        <PostFeedbackContainer

          feedbackPermission={feedbackPermission}
          setGivenKudos={setGivenKudos}
          Recipient={Recipient}
          newKudosAdded={newKudosAdded}
          setRecipient={setRecipient}
          recipientName={recipientName}
          setrecipientName={setrecipientName}
          okrList={okrList}
          setokrList={setokrList}
          okrid={okrid}
          badgeAwardId={badgeAwardId}
          setBadgeAwardId={setBadgeAwardId}
          form={form}
          setokrid={setokrid}
          feedBackType={feedBackType}
          setFeedBackType={setFeedBackType}
          fillInOption={fillInOption}
          setfillInOption={setfillInOption}
          questionList={questionList}
          setquestionList={setquestionList}
          answersList={answersList}
          setAnswers={setAnswers}
          setFeedbackDetails={setFeedbackDetails}
          setFeedbackDetailsLoading={setFeedbackDetailsLoading}
          setFeedbackModalVisible={setFeedbackModalVisible}
          question1Field1={question1Field1}
          setquestion1Field1={setquestion1Field1}
          loading={loading} setloading={setloading}
          receipientNameInvalid={receipientNameInvalid}
          setReceipientNameInvalid={setReceipientNameInvalid}
          question1Field2={question1Field2} setquestion1Field2={setquestion1Field2}
          question2Field1={question2Field1} setquestion2Field1={setquestion2Field1}
          question2Field2={question2Field2} setquestion2Field2={setquestion2Field2}
          question2Field3={question2Field3} setquestion2Field3={setquestion2Field3}
          question3Field1={question3Field1} setquestion3Field1={setquestion3Field1}
          question3Field2={question3Field2} setquestion3Field2={setquestion3Field2}
          question4Field1={question4Field1} setquestion4Field1={setquestion4Field1}
          question5Field2={question5Field2} setquestion5Field2={setquestion5Field2}
          question5Field1={question5Field1} setquestion5Field1={setquestion5Field1}
          navigationFromOkr={navigationFromOkr} setnavigationFromOkr={setnavigationFromOkr}
          hideselection={hideselection} sethideselection={sethideselection}
          employeeList={employeeList} setemployeeList={setemployeeList}
          invalidFeedbackType={invalidFeedbackType} setInvalidFeedbackType={setInvalidFeedbackType}
          selectedTabValue={selectedTabValue} setSelectedTabValue={setSelectedTabValue}
          selectedOkr={selectedOkr} setSelectedOkr={setSelectedOkr}
          checkBoxSelection={checkBoxSelection} setCheckBoxSelection={setCheckBoxSelection}
          submittedFeedback={submittedFeedback} setSubmittedFeedback={setSubmittedFeedback}
          tabArray={tabArray} setTabArray={setTabArray}
          questionSet={questionSet} setQuestionSet={setQuestionSet}
          resetStateOfElemets={resetStateOfElemets}
          setFeedbackPreviewDisplay={setFeedbackPreviewDisplay}
          resetFeedbackForm={resetFeedbackForm}
          feedBackTypeEnum={feedBackTypeEnum}
          setFeedbackDetailSide = {setFeedbackDetailSide}
          handleValidationwithFillin={handleValidationwithFillin}
          tabArrayDetailed={tabArrayDetailed} setTabArrayDetailed={setTabArrayDetailed}
          givenKudos={givenKudos} setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} setFeedbackOperationsModalVisible={setFeedbackOperationsModalVisible} {...props}

        />
      </Modal>

      <Modal visible={giveKudosPopupVisible}
        footer={null}
        centered
        closable={false}
        width={"fit-content"}>
        <GiveKudosPopupContainer setNewKudosAdded={setNewKudosAdded} feedbackById={feedbackById} setBadgeAwardId={setBadgeAwardId} badgeAwardId={badgeAwardId} setGivenKudos={setGivenKudos} feedbackById={feedbackById} feedbackEditModalVisible={feedbackEditModalVisible} setFeedbackEditModalVisible={setFeedbackEditModalVisible} setFeedbackOperationsModalVisible={setFeedbackOperationsModalVisible} setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} {...props} ></GiveKudosPopupContainer>
      </Modal>


      <Modal visible={feedbackModalVisible}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <Spin spinning={feedbackDetailsLoading}>
          <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackPreviewDisplay={feedbackPreviewDisplay}
            feedbackDetails={feedbackDetails} setFeedbackModalVisible={setFeedbackModalVisible}
            setFeedbackPreviewDisplay={setFeedbackPreviewDisplay} me={me}
            fillInOption={fillInOption}
            setFeedbackOperationsModalVisible={setFeedbackOperationsModalVisible}
            handleValidation={handleValidation}
            setFeedbackDetails={setFeedbackDetails}
            feedbackDetailSide = {feedbackDetailSide}
            handleValidationwithFillin={handleValidationwithFillin}
            editFeedbackPreview={false}
          />
        </Spin>
      </Modal>
      
    </>
  );
};

export default React.memo(NewFeedbackHome);

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`

const CreateButton = styled(Button)`
width:170px;
height:40px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: white;
&:hover {
  color: white;
  };

`

const CreateButtonNew = styled(Button)`
width:170px;
height:40px;
border-radius:5px;
color: #48ad24;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
&:hover {
color: white;
};

`