import gql from "graphql-tag";

export const USER_BASIC_DETAIL = gql`
query myprofile($personality_survey: ID,$strength_survey: ID,$value_survey: ID,$personality_questions:[ID],$diversity_survey: ID,$diversity_questions:[ID]){
    me{
        id
        firstName
        lastName
        email
        profile{
            id
            profileImage
        }
        employee{
            id
            memberSet(role_Isnull:false,last:1){
                edges{
                    node{
                        id
                        role{
                            id
                            title
                            employeeLevel {
                              id
                              name
                            }
                            jobfamilySet(last:1){
                                edges{
                                    node{
                                        id
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        strength: responseSet( survey: $strength_survey,last:1) {
        edges {
          node {
            id
            answerSet(first:3) {
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      value: responseSet( survey: $value_survey,last:1) {
        edges {
          node {
            id
            answerSet (first:3){
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      diversity: responseSet( survey: $diversity_survey,last:1) {
        edges {
          node {
            id
            answerSet(question_In:$diversity_questions) {
              edges {
                node {
                  id
                  question{
                    id
                  }
                  answer
                }
              }
            }
          }
        }
      }

        personality: responseSet( survey: $personality_survey) {
        edges {
          node {
            id
            answerSet(question_In:$personality_questions) {
              edges {
                node {
                  id
                  answer
                  sequence
                  question{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
}
`

// export const CREATE_TIP_SETTINGS =gql`
// mutation createTipsettings($active: Boolean!,$module: String!){
//   createTipsettings(active:$active,module:$module){
//     tipsettings{
//       id
// 		}
//   }
// }
// `
// export const UPDATE_TIP_SETTINGS =gql`
// mutation updateTipsettings($active: Boolean!,$id: ID!){
//   updateTipsettings(active:$active,id:$id){
//     tipsettings{
//       id
// 		}
//   }
// }
// `
// export const TIP_SETTINGS_LIST =gql`
// query tipSettingsByUser{
// tipSettingsByUser{
//      edges{
//       node{
//         id
//         module
//         active
//       }
//     }
//   }
// }
// `