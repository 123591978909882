// @ts-nocheck
import { Button, Col, Row } from "antd";
import { USER_IMG } from "config";
import React from "react";
import '../components/css/FeedBackPopupStyles.css'
import Agenda_close from '../../assets/agenda-close.svg'
import okr_route from '../../Objective-keyresult/route/index'
import { getIntFromString, getMomentUserTimezoneWithFormat, ReactMarkdown } from "modules/look";



// Feedback Detail view which will be visible in a modal

const FeedbackDetailView = props => {
    const { me, setFeedbackModalVisible, feedbackDetails, feedbackPermission, setFeedbackOperationsModalVisible, feedbackPreviewDisplay,
        setFeedbackPreviewDisplay, fillInOption, handleValidation, handleValidationwithFillin, editFeedbackPreview, setFeedbackEditModalVisible, setFeedbackDetails, feedbackDetailSide } = props

    const okrroute = (okr) => {
       
        if (okr?.okrType === "OBJECTIVE") {
            window.open(okr_route?.viewObjective?.replace(':okrId', getIntFromString(okr?.id)), "_blank");
        }
        else if (okr?.okrType === "KEY_RESULT") {
            window.open(okr_route?.keyResultDetail?.replace(':okrId', getIntFromString(okr?.id)), "_blank");
        }
        else if (okr?.okrType === "MILESTONE") {
            window.open(okr_route?.milestoneDetail?.replace(':okrId', getIntFromString(okr?.id)), "_blank");
        }

    }


    const handleFeedbackPreviewSubmission = () => {

        if (fillInOption) {
            handleValidationwithFillin(true)
        } else {
              if(editFeedbackPreview){
                handleValidation(false)
              }else{
                handleValidation(true)
              }
            
        }

    }

    const handleGobackPreview = () => {
        try {
            switch (feedbackPreviewDisplay) {

                case true:
    
                    if (editFeedbackPreview) {
                        setFeedbackEditModalVisible(true)
                    } else {
                        setFeedbackOperationsModalVisible(true);
                    }
                    
                case false:
    
                    setFeedbackDetails(undefined)
                    setFeedbackModalVisible(false);
                    setFeedbackPreviewDisplay(false)

                default:
                    setFeedbackDetails(undefined)
                    setFeedbackModalVisible(false);
                    setFeedbackPreviewDisplay(false)
            }
        } catch (error) {
            setFeedbackModalVisible(false);
        }
    }




    return (
        <div className="poppins">
            {/* Header of modal */}
            <Row justify="space-between" className="space-between-elements-feedback" >
                <Col span={24}>


                    <Row style={{ alignItems: feedbackPreviewDisplay ? "center" : "flex-end", gap: '10px' }} wrap={false} >
                        <Col xs={8} sm={8} md={5} lg={5}>
                            <img
                                alt=""
                                src={me?.profile?.profileImage || USER_IMG}
                                style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                            />
                        </Col>
                        <Col xs={16} sm={16} md={19} lg={19} className='user-info-view-feedback-popup'>


                            {
                                feedbackDetailSide === 'submitted' ?
                                    <div>


                                        <Row justify="space-between">
                                            <div className="text-ellipsis-feedback"><span className="feedback-user">To, </span><span style={{ fontWeight: '700' }} className="feedback-user">{feedbackDetails?.feedbackTo?.user?.firstName}&nbsp;{feedbackDetails?.feedbackTo?.user?.lastName} </span></div>
                                            <img src={Agenda_close} onClick={(e) => handleGobackPreview()} alt=""
                                                style={{ top: feedbackPreviewDisplay ? "-25px" : "0px" }}
                                                className="close-modal-feedback" />
                                        </Row>
                                        {
                                            !feedbackPreviewDisplay &&
                                            <div>
                                                <div className="fw-500-feedback" style={{ marginBottom: '3px' }}>Received on: <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(feedbackDetails?.createdAt, 'DD MMM YYYY')}</span></div>
                                                <div className="fw-500-feedback" >Updated on: <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(feedbackDetails?.updatedAt, 'DD MMM YYYY')}</span></div>
                                            </div>
                                        }
                                    </div>

                                    :
                                    <div>


                                        <Row justify="space-between">
                                            <div className="text-ellipsis-feedback"><span className="feedback-user">From, </span><span style={{ fontWeight: '700' }} className="feedback-user">{feedbackDetails?.feedbackFrom?.user?.firstName}&nbsp;{feedbackDetails?.feedbackFrom?.user?.lastName} </span></div>
                                            <img src={Agenda_close} onClick={(e) => handleGobackPreview()} alt=""
                                                style={{ top: feedbackPreviewDisplay ? "-25px" : "0px" }}
                                                className="close-modal-feedback" />
                                        </Row>
                                        {
                                            !feedbackPreviewDisplay &&
                                            <div>
                                                <div className="fw-500-feedback" style={{ marginBottom: '3px' }}>Received on: <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(feedbackDetails?.createdAt, 'DD MMM YYYY')}</span></div>
                                                <div className="fw-500-feedback" >Updated on: <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(feedbackDetails?.updatedAt, 'DD MMM YYYY')}</span></div>
                                            </div>
                                        }
                                    </div>
                            }



                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Kudos Details of feedback */}

            {
                feedbackDetails && feedbackPermission?.kudos_permission && feedbackDetails?.badgeAward?.edges?.map((node) =>
                    <Row className="space-between-elements-feedback" align="middle" style={{ gap: '30px' }} wrap={false}>
                        <Col>
                            <img style={{ width: '59px', height: '59px' }} src={node?.node?.badge?.image} />
                        </Col>
                        <Col>
                            <div>
                                <div style={{ fontWeight: '700' }}>{node?.node?.badge?.title}</div>
                                <div className="fw-500-feedback">{node?.node?.badge?.behaviouralDescription}</div>
                            </div>
                            <div className="fw-500-feedback" style={{ marginTop: "5px" }}>Expiry Date: <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(node?.node?.expirationDate, 'DD MMM YYYY')}</span></div>
                        </Col>
                    </Row>
                )
            }

            {/* Type of feedback */}
            <div>
                {
                    feedbackDetails?.feedbackType === "DEVELOPMENTAL_CONSTRUCTIVE" ?
                        <div style={{ padding: "8px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", width: '70%' }} className="fw-500-feedback"> Developmental/Constructive Feedback</div>
                        :
                        <div style={{ padding: "8px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", width: '70%' }} className="fw-500-feedback">Motivational/Recognition Feedback</div>
                }

            </div>


            {/* Timeline of feedback */}
            <Row className="space-between-elements-feedback" style={{ gap: '20px' }} wrap={false} >
                {
                    !feedbackPreviewDisplay &&
                    <div style={{ fontSize: '10px', marginLeft: '15px' }} className="fw-700-feedback"> LAST UPDATED:  <span style={{ color: '#009AF1' }}>{getMomentUserTimezoneWithFormat(feedbackDetails?.updatedAt, 'DD MMM YYYY')}</span></div>
                }

                <div style={{ fontSize: '10px' }} className="fw-700-feedback">NATURE: <span style={{ color: '#4CCB1F' }}>{feedbackDetails?.feedbackType === "DEVELOPMENTAL_CONSTRUCTIVE" ? "CLAPS" : "CLAS"}</span></div>
            </Row>


            {/* Details of feedback */}

            <div className="space-between-elements-feedback">

                <div className="feedback-wrapper" style={{ background: "#FFF", border: "1px solid #D9D9D9", borderRadius: "8px", maxHeight: '250px', overflow: 'auto' }}>

                    <div style={{ padding: "14px 20px 5px 20px" }}>
                        {
                            feedbackDetails?.okr && feedbackPermission?.okr_permission &&
                            <span className="fw-500-feedback"><span style={{ color: '#31D0AA' }}>OKR : </span>
                                <span style={{ cursor: 'pointer',fontWeight:'700' }} onClick={(e) => okrroute(feedbackDetails?.okr)} > {feedbackDetails?.okr?.title}</span>
                            </span>
                        }
                    </div>

                    {
                        feedbackDetails && feedbackDetails?.response?.answerSet?.edges?.map((answer) =>
                            <div className="fw-500-feedback" style={{ padding: "10px 20px", marginBottom: '10px', width: '100%', display: 'flex', flexDirection: "row", gap: "10px", flexWrap: 'nowrap' }}>
                                <div style={{ marginRight: answer?.node?.answer.toString()?.includes('<ol>') || answer?.node?.answer.toString()?.includes('<ul>') ? '20px' : '0px', display: 'flex', flexDirection: "row", gap: "5px", }}><ReactMarkdown style={{ color: '#4CCB1F' }}>{answer?.node?.question?.questionText[3]}</ReactMarkdown><span style={{ fontWeight: "600", color: '#4CCB1F' }}>:</span></div>
                                <div><ReactMarkdown style={{ marginLeft: answer?.node?.question?.questionText[3] === 'L' ? '4px' : '0px', marginBottom: '0px' }}>{answer?.node?.answer}</ReactMarkdown></div>
                            </div>
                        )
                    }
                </div>

                {
                    feedbackPreviewDisplay &&
                    <div>
                        {
                            <Row justify="center" style={{ gap: '10px', marginTop: '15px' }} >
                                <Button className="feedback-cancel-btn-modal" style={{ padding: '7px 17px' }}
                                    onClick={(e) => handleGobackPreview()}>GO BACK</Button>
                                <Button className="feedback-save-btn-modal" type="primary" onClick={(e) => handleFeedbackPreviewSubmission()} >Submit</Button>
                            </Row>
                        }

                    </div>
                }

            </div>
        </div>
    );
};

export default FeedbackDetailView;

