export const ai_trigger_content = {
    "idp_home_content": [
        {
            title: "Individual Development Plan",
            situation: "Can you provide a brief overview of what an Individual Development Plan (IDP) is?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "What is an Individual Development Plan, in simple terms?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "Could you describe the purpose of an Individual Development Plan in a few sentences?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "Give a short summary of an Individual Development Plan and its benefits.",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "What does an Individual Development Plan involve, explained briefly?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "In a quick overview, what is an IDP and how is it used?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "How would you explain an Individual Development Plan to someone new to the concept?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "Can you give a concise description of an IDP and its main objectives?",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "Summarize the key purpose of an Individual Development Plan in a few words.",
            from_cache: true
        },
        {
            title: "Individual Development Plan",
            situation: "What’s the basic idea behind an Individual Development Plan?",
            from_cache: true
        }
    ],
    "idp_create_Short": [
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Can you guide me through creating a short-term Individual Development Plan?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Help me set up a short-term development plan with specific, achievable goals.`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Could you assist me in outlining a short-term Individual Development Plan?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `What steps should I take to create a focused, short-term development plan?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Can you provide tips for setting up short-term goals in my Individual Development Plan?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Assist me in defining clear, short-term objectives for my development plan.`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `How can I create a practical, short-term plan to reach my development goals?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Help me design a short-term IDP with actionable steps and realistic goals.`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `Can you walk me through setting up a short-term development plan that I can complete in the next few months?`,
            from_cache: true
        },
        {
            title: "Create Short Term Individual Development Plan",
            situation: `I'd like some guidance on creating a short-term IDP—can you help me get started?`,
            from_cache: true
        }
    ],
    "idp_create_Long": [
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Can you guide me in building a long-term Individual Development Plan with strategic goals?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Help me outline a long-term development plan to achieve my professional and personal growth objectives.`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Could you assist me in creating a long-term IDP focused on my future career path?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `What steps should I take to develop a sustainable, long-term development plan?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Can you provide tips for setting up meaningful, long-term goals in my IDP?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Assist me in planning long-term objectives for my Individual Development Plan.`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `How can I build a long-term IDP with strategic, future-focused goals?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Help me design a comprehensive long-term development plan with milestones.`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `Can you walk me through setting up a long-term IDP that supports my 5 to 10 year goals?`,
            from_cache: true
        },
        {
            title: "Create Long Term Individual Development Plan",
            situation: `I'd like some guidance on creating a long-term IDP—can you help me structure it effectively?`,
            from_cache: true
        }
    ],
    "idp_update_Short": [
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Assist me in updating my short-term individual development plan.`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Assist me in reviewing and adjusting my short-term development plan.`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Could you guide me in refreshing my short-term IDP to reflect recent progress?`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `What steps should I take to update my short-term development plan?`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Can you suggest ways to revise my short-term IDP with current objectives?`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Help me refine my short-term development plan to align with recent changes.`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `How can I adjust my short-term IDP to incorporate new priorities?`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Assist me in updating my short-term goals within my Individual Development Plan.`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `Can you guide me through the process of revising my short-term IDP for relevance?`,
            from_cache: true
        },
        {
            title: "Update Short Term Individual Development Plan",
            situation: `I'd like to update my short-term development plan—can you help me make adjustments?`,
            from_cache: true
        }
    ],
    "idp_update_Long": [
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Assist me in updating my long-term individual development plan.`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Assist me in reviewing and adjusting my long-term development plan to reflect recent changes.`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Can you help me update my long-term Individual Development Plan with revised goals?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Could you guide me in refreshing my long-term IDP to align with my evolving objectives?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `What steps should I take to update my long-term development plan for future success?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Can you suggest ways to revise my long-term IDP with updated priorities?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Help me refine my long-term development plan to stay aligned with my current vision.`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `How can I adjust my long-term IDP to incorporate new skills and goals?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `Can you walk me through revising my long-term IDP for continued relevance?`,
            from_cache: true
        },
        {
            title: "Update Long Term Individual Development Plan",
            situation: `I'd like to update my long-term development plan—can you help me make strategic adjustments?`,
            from_cache: true
        }
    ],
    "idp_inprogress": [
        {
            title: "Complete Individual Development Plan",
            situation: `Can you help me finalize my Individual Development Plan before the due date`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Assist me in completing my IDP efficiently to meet the deadline.`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Could you guide me in organizing my tasks to ensure I finish my Individual Development Plan on time?`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `What steps should I take to complete my IDP by the due date?`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Can you suggest strategies for staying on track to finish my Individual Development Plan promptly?`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Help me create a timeline to complete my IDP before the deadline.`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `How can I prioritize my goals to ensure my IDP is finished on time?`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Assist me in outlining a plan to meet the due date for my Individual Development Plan.`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `Can you provide tips for managing my time effectively to complete my IDP by the deadline?`,
            from_cache: true
        },
        {
            title: "Complete Individual Development Plan",
            situation: `I'd like guidance on finalizing my IDP quickly—can you help me stay focused and meet my due date?`,
            from_cache: true
        }
    ],
    "idp_pending_approval": [
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `My manager has not approved my Individual Development Plan. Can you help me understand the next steps I should take?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `What should I do if my IDP is still pending approval from my manager?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `Assist me in navigating the process after my manager rejected my Individual Development Plan.`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `How can I address my manager's concerns to get my IDP approved?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `Can you suggest ways to modify my Individual Development Plan to meet my manager's expectations?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `Help me create a strategy for discussing my IDP with my manager again.`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `What approach should I take to ensure my IDP gets approved this time?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `Can you provide tips for presenting my IDP more effectively to my manager?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `How can I gather feedback on my IDP to improve it for approval?`,
            from_cache: true
        },
        {
            title: "Pending Approval For Individual Development Plan",
            situation: `I'd like guidance on how to proceed after my manager did not approve my IDP—can you assist me?`,
            from_cache: true
        }
    ],
    "idp_revise": [
        {
            title: "Revise Individual Development Plan",
            situation: `Can you help me revise my Individual Development Plan to better align with my goals?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Assist me in making improvements to my IDP for better clarity and effectiveness.`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Could you guide me through the process of revising my Individual Development Plan?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `What specific areas of my IDP should I focus on revising for optimal impact?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Can you suggest strategies for updating my IDP to reflect my current priorities?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Help me identify gaps in my IDP that need to be addressed in the revision process.`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `How can I modify my goals in the Individual Development Plan to make them more achievable?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Assist me in refining the action steps in my IDP to enhance my development strategy.`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `Can you provide tips for ensuring my revised IDP is comprehensive and actionable?`,
            from_cache: true
        },
        {
            title: "Revise Individual Development Plan",
            situation: `I'd like to improve my Individual Development Plan—can you help me with effective revision techniques?`,
            from_cache: true
        }
    ],
    "idp_due_date_pass": [
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `My IDP milestone due date has passed. Can you help me figure out the next steps to move forward?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `What should I do now that I've missed the due date for my IDP milestone?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `Assist me in developing a plan to catch up on my Individual Development Plan milestones.`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `How can I address the missed deadline for my IDP and still stay on track?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `Can you provide strategies for revising my timeline after missing my IDP milestone due date?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `Help me identify how to communicate with my manager about my missed IDP milestone.`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `What steps should I take to reassess my IDP goals now that my milestone due date has passed?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `Can you suggest ways to prioritize my tasks to make up for the delayed IDP milestone?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `How can I create a recovery plan to get back on track with my Individual Development Plan?`,
            from_cache: true
        },
        {
            title: "Individual Development Plan Milestone Duedate Passed",
            situation: `I need guidance on how to proceed after my IDP milestone was not met—can you assist me?`,
            from_cache: true
        }
    ],
    "idp_due_date_soon": [
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `My IDP milestone for {{dynamic_1}} is due in a few days. Can you help me develop a plan to achieve this goal?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `With the milestone due date for {{dynamic_1}} approaching, what steps should I take to ensure I complete it on time?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `Assist me in creating a timeline to achieve my goal for {{dynamic_1}} before the upcoming due date.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `How can I maximize my efforts to meet the milestone for {{dynamic_1}} that's due soon?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `Can you provide tips for prioritizing tasks to ensure I achieve my {{dynamic_1}} milestone on time?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `What strategies can I use to stay focused and on track as I work towards my {{dynamic_1}} goal?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `Help me outline the necessary steps to complete my {{dynamic_1}} milestone before the deadline.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `How can I effectively manage my time to meet the due date for my {{dynamic_1}} milestone?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `Can you suggest ways to overcome potential obstacles as I approach the due date for {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Individual Development Plan Milestone Duedate Coming Up",
            situation: `I need guidance on how to achieve my goal for {{dynamic_1}} within the next few days—can you assist me?`,
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "create_coaching_engagement": [
        {
            title: "Create coaching Conversation",
            situation: `Can you help me prepare for an effective coaching conversation with my team member?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `Assist me in structuring a coaching conversation to support growth and development.`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `Could you guide me in creating a productive coaching conversation outline?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `What key elements should I include in a coaching conversation to make it impactful?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `Help me develop questions and talking points for a successful coaching conversation.`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `Can you provide strategies to ensure my coaching conversation is engaging and supportive?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `How can I set goals for a coaching conversation to achieve clear outcomes?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `Assist me in creating a coaching conversation that fosters open communication and trust.`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `What steps should I take to prepare for an insightful coaching conversation?`,
            from_cache: true
        },
        {
            title: "Create coachingConversation",
            situation: `I'd like guidance on planning an effective coaching conversation—can you help?`,
            from_cache: true
        }
    ],
    "okr_create_objective": [
        {
            title: "Create Objective",
            situation: "Help me formulate a clear and actionable objective that aligns with my career goals and personal aspirations.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Assist me in developing a SMART objective that I can use as a guide for my professional growth and development.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Can you help me create a focused objective that reflects my strengths and targets specific areas for improvement?",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Support me in outlining my objective by identifying key milestones and success metrics for my career path.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Please assist me in crafting an objective that is both challenging and attainable, keeping my long-term goals in mind.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Help me articulate an objective that encompasses my values and aspirations while aligning with my current role.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Guide me in creating a concise objective that serves as a foundation for my professional development plan.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Can you assist me in designing an objective that addresses both my career aspirations and the needs of my organization?",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Support me in establishing an objective that motivates me and provides direction for my future projects.",
            from_cache: true
        },
        {
            title: "Create Objective",
            situation: "Help me create a well-defined objective that I can use to measure my progress and keep me accountable in my career.",
            from_cache: true
        }
    ],
    "okr_create_keyresult": [
        {
            title: "Create Key Result",
            situation: "Help me define a key result that aligns with the objective {{dynamic_1}} and ensures measurable progress.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Assist me in formulating a specific key result for the objective {{dynamic_1}}, focusing on what success looks like.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Can you help me create a key result that supports the objective {{dynamic_1}} and sets clear expectations?",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Guide me in developing a key result under the objective {{dynamic_1}}, ensuring it's both challenging and attainable.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Please assist me in outlining a key result for the objective {{dynamic_1}} that will help me track my progress effectively.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Help me identify a key result that aligns with the objective {{dynamic_1}} and focuses on specific outcomes.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Support me in creating a measurable key result for the objective {{dynamic_1}} that contributes to my overall goals.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Can you assist me in crafting a key result under the objective {{dynamic_1}} that motivates me to take action?",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Please guide me in developing a key result for the objective {{dynamic_1}} that I can monitor and evaluate regularly.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Key Result",
            situation: "Help me articulate a key result that clearly defines success under the objective {{dynamic_1}}, making it easier to track my achievements.",
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "okr_create_milestone": [
        {
            title: "Create Milestone",
            situation: "Help me define a milestone that supports the key result {{dynamic_1}} and outlines critical steps for success.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Assist me in formulating a specific milestone for the key result {{dynamic_1}}, focusing on achievable and time-bound targets.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Can you help me create a milestone that aligns with the key result {{dynamic_1}} and marks significant progress in my journey?",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Guide me in developing a milestone under the key result {{dynamic_1}}, ensuring it's both measurable and motivating.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Please assist me in outlining a milestone for the key result {{dynamic_1}} that will help me track my progress effectively.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Help me identify a milestone that aligns with the key result {{dynamic_1}} and specifies a clear timeline for achievement.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestonee",
            situation: "Support me in creating a measurable milestone for the key result {{dynamic_1}} that contributes to my overall goals.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Can you assist me in crafting a milestone under the key result {{dynamic_1}} that will keep me accountable and focused?",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Please guide me in developing a milestone for the key result {{dynamic_1}} that I can monitor and evaluate regularly.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Milestone",
            situation: "Help me articulate a milestone that clearly defines my next steps under the key result {{dynamic_1}}, making it easier to achieve my goals.",
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "okr_due_date_passed": [
        {
            title: "Objective Due Date Passed",
            situation: "My objective's due date has passed. Please help me understand my next steps to get back on track.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "The due date for my objective has passed. Assist me in evaluating my progress and planning my next actions.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "Since my objective's due date has lapsed, can you help me identify what actions I need to take to move forward?",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "My objectives are overdue. Guide me on how to adjust my plans and meet my goals effectively.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "Help me strategize my next steps now that my objective's due date has passed and I need to refocus my efforts.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "The deadline for my objective has passed. Assist me in reassessing my priorities and moving forward.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "My objective's due date has come and gone. Can you help me create a revised plan to achieve my goal?",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "Since my objective is overdue, please assist me in identifying the obstacles I faced and how to overcome them.",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "I need help moving forward as my objective's due date has passed. What steps should I take to get back on track?",
            from_cache: true
        },
        {
            title: "Objective Due Date Passed",
            situation: "My objectives are overdue. Help me analyze the title and suggest ways to regain momentum in my work.",
            from_cache: true
        }
    ],
    "okr_due_date_upcoming": [
        {
            title: "Objective Due Date Upcoming",
            situation: "My objective's due date is approaching in a few days. Please help me outline the tasks I need to complete before the deadline.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "With my objective's due date coming up soon, assist me in creating a timeline to ensure I meet the deadline.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "My objective is due in a few days. Can you help me prioritize my tasks to ensure everything is completed on time?",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "The deadline for my objective is fast approaching. Help me identify any remaining steps I need to take to complete it.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "I need to complete my objective before the due date in a few days. Assist me in creating a checklist of what needs to be done.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "My objective's deadline is coming up soon. Help me develop a plan to ensure I finish everything on time.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "With the due date for my objective just around the corner, please assist me in breaking down the tasks to ensure timely completion.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "I need to stay focused as my objective's due date is approaching. Help me strategize how to effectively complete it on time.",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "My objective is due in a few days. Can you help me create a last-minute action plan to get it done?",
            from_cache: true
        },
        {
            title: "Objective Due Date Upcoming",
            situation: "With the deadline for my objective approaching, please assist me in reviewing my progress and what I still need to accomplish.",
            from_cache: true
        }
    ],
    "okr_summary": [
        {
            title: "OKR Summary",
            situation: "Please generate a summary of the OKR {{dynamic_1}}. The due date is on {{dynamic_2}}, and the current progress is {{dynamic_3}}%. Additional notes: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Can you provide a summary of my OKR {{dynamic_1}}? The due date is {{dynamic_2}}, with current progress at {{dynamic_3}}%. Here are some remarks: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Generate a detailed summary for OKR {{dynamic_1}}. The due date is approaching on {{dynamic_2}}, and I’m currently at {{dynamic_3}}%. Notes: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Summarize the OKR {{dynamic_1}} for me. It's due on {{dynamic_2}}, and the progress is currently {{dynamic_3}}%. Additional information: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Help me generate a summary for OKR {{dynamic_1}}. The due date is {{dynamic_2}} and I'm at {{dynamic_3}}% completion. Remarks: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Please create a summary for my OKR {{dynamic_1}}. It's due on {{dynamic_2}}, and my progress stands at {{dynamic_3}}%. Additional notes: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Can you generate a summary of the OKR {{dynamic_1}}? The due date is on {{dynamic_2}}, with current progress at {{dynamic_3}}%. Here are the details: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Summarize the status of OKR {{dynamic_1}} for me. The due date is {{dynamic_2}}, and I have completed {{dynamic_3}}%. Remarks: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Provide a summary for OKR {{dynamic_1}}. Due date: {{dynamic_2}}. Current progress: {{dynamic_3}}%. Additional insights: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        },
        {
            title: "OKR Summary",
            situation: "Help me with a summary for my OKR {{dynamic_1}}. The due date is approaching on {{dynamic_2}}, and my current progress is {{dynamic_3}}%. Notes: {{dynamic_4}}.",
            from_cache: true,
            dynamic_data: 4
        }
    ],
    "motivational_feedback": [
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Can you help me craft meaningful motivational feedback for my team?
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Assist me in creating recognition feedback that highlights specific achievements.
             these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Could you guide me in writing motivational feedback to boost my team's morale?
             these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} What should I include in recognition feedback to make it impactful and sincere?
             these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Help me design motivational feedback that encourages continued effort and growth.
             these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Can you provide tips for giving motivational recognition feedback that feels genuine and specific?
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} How can I create motivational feedback that celebrates achievements while inspiring future success?
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} Assist me in crafting motivational feedback that recognizes accomplishments and motivates my team.
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} What are some effective ways to structure motivational feedback to keep the team energized?
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Motivational Feedback",
            situation: `{{dynamic_1}} I'd like help in developing motivational feedback that truly acknowledges hard work—can you assist?
            these are our Motivational Feedback types:
                C -Create Safety by stating how the feedback will help the receiver.
                L - Lay out context by referring to a specific situation, so the feedback is not generalized.
                A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
                S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "developmental_feedback": [
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Can you help me craft constructive developmental feedback to support someone's growth?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Assist me in creating developmental feedback that encourages improvement.
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Could you guide me in giving constructive developmental feedback in a way that’s supportive and helpful?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} What should I include in my developmental feedback to make it constructive and actionable?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Help me design developmental feedback that provides clear areas for improvement.
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Can you provide tips for giving developmental feedback that addresses challenges without discouraging?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} How can I structure constructive developmental feedback to focus on growth opportunities?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} Assist me in creating developmental feedback that’s both encouraging and helpful for improvement.
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} What are effective ways to frame constructive developmental feedback to foster positive change?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Create Developmental Feedback",
            situation: `{{dynamic_1}} I'd like guidance on providing developmental feedback that motivates progress—can you help?
             these are our Developmental Feedback types:
            C -Create Safety by stating how the feedback will help the receiver.
            L - Lay out context by referring to a specific situation, so the feedback is not generalized.
            A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.
            P - Probe for possible alternatives that the receiver can try to act on the feedback.
            S - Support for next steps and commitments by offering help, advice and by being an accountability partner.`,
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "developmental_feedback_summery": [
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} Could you guide me on the next steps to take based on the Developmental/Constructive feedback I've received?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} What actions should I take in response to the Developmental/Constructive feedback provided?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} Please advise me on the best next steps for acting on my received Developmental/Constructive feedback.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} How should I move forward based on the Developmental/Constructive feedback I've received?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} I’d appreciate guidance on the next actions to take following my Developmental/Constructive feedback.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} What steps would you recommend based on the feedback I’ve received for my Developmental/Constructive?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} Could you help me identify the next steps to address the Developmental/Constructive feedback I’ve been given?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} Please help me determine the next course of action in response to my Developmental/Constructive feedback.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} What would be the best way to proceed after receiving my Developmental/Constructive feedback?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Developmental Feedback",
            situation: `{{dynamic_1}} I’d like some advice on the next steps to take to act on the Developmental/Constructive feedback I received.`,
            from_cache: true,
            dynamic_data: 1
        },
    ],
    "give_kudos": [
        {
            title: "Award Kudos For Feedback",
            situation: `Can you help me write feedback that includes kudos for a job well done?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `Assist me in crafting a message that gives kudos to recognize great work.`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `Could you guide me in giving kudos in feedback to highlight specific achievements?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `Help me create kudos-focused feedback that celebrates accomplishments`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `Can you provide tips for giving kudos in feedback that shows appreciation and recognition?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `How can I phrase feedback to effectively give kudos for exceptional performance?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `Assist me in giving kudos within my feedback to inspire and motivate my team.`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `What’s an effective way to incorporate kudos in feedback to show appreciation?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            situation: `I'd like guidance on giving kudos in feedback to recognize and encourage continued excellence.`,
            from_cache: true
        }
    ],

    "readiness_for_others": [
        {
            title: "Create Readiness For Others",
            situation: `Can you help me develop a readiness plan for my team members?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `Assist me in creating a readiness assessment for my team to ensure they’re prepared.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `Could you guide me in designing strategies to improve my team’s readiness?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `What should I include in a readiness plan to ensure my team is fully prepared for upcoming tasks?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `Help me create a readiness framework that supports my team’s development and preparedness.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `Can you provide tips for evaluating and building readiness among my team members?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `How can I create a readiness strategy to support my team in meeting project goals?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `Assist me in identifying key areas of readiness to focus on for my team’s growth.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `What are effective ways to assess and increase readiness across my team?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            situation: `I'd like guidance on developing a readiness plan that prepares my team for new challenges.`,
            from_cache: true
        }
    ],
    "readiness_for_my_self": [
        {
            title: "Create Readiness For My Self",
            situation: `Can you help me develop a readiness plan to prepare myself for upcoming challenges?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `Assist me in assessing and building my own readiness for new responsibilities.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `Could you guide me in creating a personal readiness plan to strengthen my skills?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `What steps should I take to ensure I'm fully prepared for future tasks and goals?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `Help me design a readiness strategy that supports my personal development.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `Can you provide tips to assess and improve my readiness for upcoming projects?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `How can I create a plan to boost my readiness for new opportunities?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `Assist me in identifying key areas of readiness to focus on for my personal growth.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `What are some strategies for enhancing my readiness for professional challenges?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            situation: `I'd like guidance on building a readiness plan that prepares me for future success.`,
            from_cache: true
        }
    ],
    "readiness_add_new_skill": [
        {
            title: "Add New Skill",
            situation: `Can you guide me in developing a plan to learn a new skill effectively?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `Assist me in creating a step-by-step approach to building a new skill.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `Could you help me outline a strategy to acquire a new skill quickly?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `What steps should I take to develop a new skill from scratch?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `Help me create a skill-building plan to master something new.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `Can you provide a structured approach to learning and practicing a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `How can I set realistic goals to track my progress in developing a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `Assist me in identifying resources and techniques to build a new skill.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `What are effective ways to learn and practice a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            situation: `I’d like help with creating a plan to develop a new skill—can you assist?`,
            from_cache: true
        }
    ],
    "readiness_survey_assist": [
        {
            title: "Readiness Survey Assist",
            situation: `Can you guide me through completing the readiness survey effectively?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `Assist me in understanding and responding accurately to the readiness survey questions.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `Could you help me prepare for taking the readiness survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `What tips do you have for answering the readiness survey with confidence?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `Help me approach the readiness survey to ensure thorough and honest responses.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `Can you provide guidance on how to best reflect my readiness in the survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `How should I assess myself for an accurate readiness survey response?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `Assist me in interpreting and completing each question in the readiness survey.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `What’s the best way to prepare myself for taking the readiness survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            situation: `I’d like help in filling out the readiness survey thoughtfully—can you assist?`,
            from_cache: true
        }
    ],
    "readiness_zone_based": [
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Can you give me a summary of my readiness zone for {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Assist me in understanding the summary of my readiness level in {{dynamic_1}}.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Could you provide an overview of my readiness zone for {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `What’s a brief summary of my current readiness in {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Help me understand my readiness summary for {{dynamic_1}}.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Can you give me a quick recap of my readiness zone status for {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `What are the key points of my readiness zone in {{dynamic_1}}?`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Provide a summary of how prepared I am in the {{dynamic_1}} readiness zone.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `Assist me in interpreting the summary of my readiness for {{dynamic_1}}.`,
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            situation: `I’d like an overview of my readiness zone for {{dynamic_1}}—can you help?`,
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "readiness_assessment_skill_summary": [
        {
            title: "Readiness Assessment Summary",
            situation: "Summarize {{dynamic_1}} readiness assessment, focusing on {{dynamic_2}} skill. Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        },
        {
            title: "Readiness Assessment Summary",
            situation: "Provide a detailed summary of {{dynamic_1}} {{dynamic_2}} skill from the readiness assessment. Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        },
        {
            title: "Readiness Assessment Summary",
            situation: "Can you compare {{dynamic_1}} readiness assessment results and give a summary of {{dynamic_2}} skill?. Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        },
        {
            title: "Readiness Assessment Summary",
            situation: "Give me a readiness assessment summary that highlights {{dynamic_1}} {{dynamic_2}} skill. Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        },
        {
            title: "Readiness Assessment Summary",
            situation: "Could you provide insights on {{dynamic_1}} {{dynamic_2}} skill based on readiness assessment?.  Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        },
        {
            title: "Readiness Assessment Summary",
            situation: "Help me understand {{dynamic_1}} {{dynamic_2}} skill readiness with a detailed assessment summary. Assessment details are {{dynamic_3}}",
            from_cache: true,
            dynamic_data: 3
        } 
    ]
    ,
    "one_on_one_meeting_schedule": [
        {
            title: "One on one meeting schedule",
            situation: "Can you help me schedule a one-on-one meeting by suggesting a convenient date and time?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "Assist me in setting up a one-on-one meeting, including sending a calendar invite and reminder.",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "Could you guide me in picking a suitable time and sending out an invite for a one-on-one meeting?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "What’s the best way to choose a date, send an invite, and set a reminder for a one-on-one meeting?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "Help me organize a one-on-one meeting by finding a good time, sending an invite, and setting reminders.",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "Can you assist me in selecting a convenient time and scheduling a one-on-one meeting with a calendar invite?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "How can I schedule a one-on-one meeting efficiently, including an invite and reminders?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "Assist me with setting up a one-on-one meeting, including picking a time, sending an invite, and reminders.",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "What steps should I take to arrange a one-on-one meeting with a calendar invite and reminders?",
            from_cache: true,
        },
        {
            title: "One on one meeting schedule",
            situation: "I'd like help scheduling a one-on-one meeting—could you assist with the date, invite, and reminders?",
            from_cache: true,
        }
    ],
    "one_on_one_series_meeting_schedule": [
        {
            title: "One on one series meeting",
            situation: "Can you help me schedule a series of one-on-one meetings, ensuring no conflicts and timely reminders?",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Assist me in organizing multiple one-on-one meetings, including dates, times, and calendar invites for participants.",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Could you guide me in scheduling a series of one-on-one meetings with conflict-free timing and reminders?",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Help me set up recurring one-on-one meetings, coordinating dates, invites, and reminders for each participant.",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "What’s the best approach for scheduling multiple one-on-one meetings without conflicts and with proper reminders?",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Can you support me in planning a series of one-on-ones, ensuring all participants receive invites and reminders?",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Assist me in arranging a sequence of one-on-one meetings, with dates, times, invites, and conflict management.",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "How can I organize multiple one-on-ones smoothly, with calendar invites and reminders for each attendee?",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "Help me schedule a series of one-on-one meetings with no conflicts and set reminders for all participants.",
            from_cache: true,
        },
        {
            title: "One on one series meeting",
            situation: "I’d like assistance in scheduling recurring one-on-one meetings, including invites, reminders, and conflict checks.",
            from_cache: true,
        }
    ],
    "one_on_one_create_agenda": [
        {
            title: "One on one meeting agenda",
            situation: "Can you help me create an agenda for a one-on-one meeting, including key discussion points and time allocations?",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Assist me in outlining an effective agenda for a one-on-one meeting, with topics and supporting materials.",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Could you guide me in organizing an agenda for a one-on-one meeting, including time slots for each topic?",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Help me structure an agenda for my one-on-one, with prioritized topics and time management.",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "What’s a good approach for creating a one-on-one meeting agenda that includes key points and materials?",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Can you provide tips for building a clear, focused agenda for my upcoming one-on-one meeting?",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Assist me in detailing an agenda for a one-on-one, covering discussion topics and time allocations.",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "How should I plan an agenda for a one-on-one meeting, including key topics and supporting materials?",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "Help me prepare an agenda with time allocations and essential points for my one-on-one meeting.",
            from_cache: true,
        },
        {
            title: "One on one meeting agenda",
            situation: "I’d like guidance on structuring a one-on-one meeting agenda—could you help outline topics and materials?",
            from_cache: true,
        }
    ],
    "meeting_summary": [
        {
            title: "Meeting Summary",
            situation: `Can you provide a summary for the {{dynamic_1}} meeting held on {{dynamic_2}} with {{dynamic_3}} and {{dynamic_4}}? Include action items: {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Assist me in summarizing the {{dynamic_1}} meeting from {{dynamic_2}}, covering key points, participants {{dynamic_3}} and {{dynamic_4}}, and action items {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Could you generate a summary for the {{dynamic_1}} meeting dated {{dynamic_2}}, listing the discussion highlights, participants {{dynamic_3}} and {{dynamic_4}}, and action items {{dynamic_5}}?`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Please summarize the {{dynamic_1}} meeting from {{dynamic_2}}, noting key takeaways, attendees {{dynamic_3}} and {{dynamic_4}}, and any action items: {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Help me create a concise summary for the {{dynamic_1}} meeting held on {{dynamic_2}} with {{dynamic_3}} and {{dynamic_4}}, focusing on action items {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Can you draft a summary for the {{dynamic_1}} meeting on {{dynamic_2}}? Include key points, attendees {{dynamic_3}} and {{dynamic_4}}, and action items {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Please assist in summarizing the {{dynamic_1}} meeting from {{dynamic_2}}, covering main points, attendees {{dynamic_3}} and {{dynamic_4}}, and actions {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Could you generate a brief recap of the {{dynamic_1}} meeting on {{dynamic_2}}, highlighting participants {{dynamic_3}} and {{dynamic_4}} and noting action items {{dynamic_5}}?`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `Help me prepare a summary for the {{dynamic_1}} meeting on {{dynamic_2}} with {{dynamic_3}} and {{dynamic_4}}, and list action items {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        },
        {
            title: "Meeting Summary",
            situation: `I need a meeting summary for the {{dynamic_1}} discussion on {{dynamic_2}}. Include participants {{dynamic_3}} and {{dynamic_4}} and action items {{dynamic_5}}.`,
            from_cache: true,
            dynamic_data: 5
        }
    ],
    "survey_3_completed": [
        {
            title: "Top 5 Values",
            situation: "Give me a summary of my top values and guidance on using them to advance my professional career. My values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Help me understand how to apply my top values to improve my career path. My values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Provide a summary of my core values and suggest ways to integrate them into my professional development. Values: {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Give a brief summary of my key values and how I can leverage them to enhance my career. Top values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Summarize my values and offer advice on using them to advance professionally. Values include {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Help me understand my core values and suggest ways to use them for career growth. My values: {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Give a summary of my main values and strategies to use them effectively in my career. Values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Summarize my primary values and help me leverage them to build a successful career. My values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Provide a summary of my values and ideas on how they can support my career improvement. Key values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            situation: "Help me understand how to apply my top values to enhance my career path. My core values are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Value"
        }
    ],
    "survey_3_pending": [
        {
            title: "Complete Top 5 Values Survey",
            situation: "Suggest that I complete the Top 5 Values survey to generate an impact narrative and provide details about the survey.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Recommend I take the Top 5 Values survey for an impact narrative and explain what the survey involves.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Guide me to complete the Top 5 Values survey to create an impact narrative and share information on the survey itself.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Encourage me to complete the Top 5 Values survey and provide details on how it supports an impact narrative.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Suggest I take the Top 5 Values survey to help develop an impact narrative, including information about the survey’s purpose.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Recommend completing the Top 5 Values survey for impact narrative purposes and give a brief overview of the survey.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Prompt me to complete the Top 5 Values survey to build an impact narrative, and provide some insight into the survey content.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Encourage me to take the Top 5 Values survey for impact narrative development and offer information about the survey itself.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Guide me towards completing the Top 5 Values survey and explain how it contributes to creating an impact narrative.",
            from_cache: true,
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            situation: "Suggest taking the Top 5 Values survey to generate an impact narrative and describe what the survey entails.",
            from_cache: true,
            module: "Value"
        }
    ],
    "survey_7_completed": [
        {
            title: "Top 5 Strength",
            situation: "Give me a summary of my top strengths and guidance on how to use them to advance my professional career. My strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Help me understand how to apply my top strengths to improve my career path. My strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Provide a summary of my core strengths and suggest ways to integrate them into my professional development. Strengths: {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Give a brief summary of my key strengths and how I can leverage them to enhance my career. Top strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Summarize my strengths and offer advice on using them to advance professionally. Strengths include {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Help me understand my core strengths and suggest ways to use them for career growth. My strengths: {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Give a summary of my main strengths and strategies to use them effectively in my career. Strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Summarize my primary strengths and help me leverage them to build a successful career. My strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Provide a summary of my strengths and ideas on how they can support my career improvement. Key strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            situation: "Help me understand how to apply my top strengths to enhance my career path. My core strengths are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Strength"
        }
    ],
    "survey_7_pending": [
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Suggest that I complete the Top 5 Strength survey to generate an impact narrative and provide details about the survey.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Recommend I take the Top 5 Strength survey to develop an impact narrative and explain what the survey involves.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Guide me to complete the Top 5 Strength survey to create an impact narrative and share information on the survey itself.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Encourage me to complete the Top 5 Strength survey to support an impact narrative and provide details on how the survey helps.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Suggest I take the Top 5 Strength survey for impact narrative creation, including information on the survey’s purpose.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Recommend completing the Top 5 Strength survey for developing an impact narrative and give a brief overview of the survey.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Prompt me to complete the Top 5 Strength survey to build an impact narrative and provide some insight into the survey content.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Encourage me to take the Top 5 Strength survey for impact narrative development and offer information about the survey itself.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Guide me towards completing the Top 5 Strength survey and explain how it contributes to creating an impact narrative.",
            from_cache: true,
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            situation: "Suggest taking the Top 5 Strength survey to generate an impact narrative and describe what the survey entails.",
            from_cache: true,
            module: "Strength"
        }
    ],
    "survey_8_completed": [
        {
            title: "My Personality",
            situation: "Give me a summary of my personality traits based on the following characteristics: {{dynamic_1}}. Explain how these traits influence my behavior and interactions.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Summarize my personality by analyzing these traits: {{dynamic_1}}. Provide insights on how they shape my personal and professional relationships.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Please provide a summary of my personality using the traits listed: {{dynamic_1}}. Discuss how I can use these strengths in my career.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Give me an overview of my personality traits based on {{dynamic_1}}. Include suggestions on how to maximize these traits in my daily life.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Summarize my personality characteristics as represented by {{dynamic_1}}. Share tips on how I can enhance my interactions using these traits.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Provide a comprehensive summary of my personality traits, focusing on {{dynamic_1}}. Explain how these traits can impact my decision-making.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Please summarize my personality, highlighting the traits {{dynamic_1}}. Offer advice on how to navigate challenges related to these traits.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Summarize my personality using these characteristics: {{dynamic_1}}. Discuss potential career paths that align with my personality.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Give me a detailed summary of my personality traits based on {{dynamic_1}}. Include how I can develop these traits further.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            situation: "Provide an overview of my personality with respect to these traits: {{dynamic_1}}. Suggest ways to enhance my strengths and address weaknesses.",
            from_cache: true,
            dynamic_data: 1,
            module: "Personality"
        }
    ],
    "survey_8_pending": [
        {
            title: "Complete Personality Survey",
            situation: "Encourage me to complete the personality survey to help generate my impact narrative. Please explain how understanding my personality can benefit my personal growth.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Suggest that I take the personality survey to create an impact narrative. Highlight how this can provide insights into my strengths and areas for improvement.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Prompt me to complete the personality survey for generating an impact narrative. Describe how this survey will enhance my self-awareness and decision-making.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Advise me to finish the personality survey to aid in generating my impact narrative. Emphasize the importance of understanding my personality traits.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Remind me to complete the personality survey as it is essential for generating an impact narrative. Discuss how it will assist in clarifying my career goals.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Encourage me to take the personality survey to support my impact narrative development. Share how the insights gained can inform my future choices.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Motivate me to complete the personality survey to create an impactful narrative. Explain how the results can guide my personal and professional development.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Suggest I complete the personality survey to generate my impact narrative. Provide reasons why this is a crucial step in my self-improvement journey.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Urge me to finish the personality survey as it will help me generate my impact narrative. Highlight how it can reveal key insights into my interactions with others.",
            from_cache: true,
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            situation: "Prompt me to complete the personality survey to generate an impact narrative. Discuss how this will help me better understand my motivations and values.",
            from_cache: true,
            module: "Personality"
        }
    ],
    "survey_9_completed": [
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Provide a summary of my knowledge and skills, focusing on how I can leverage them for self-improvement and to assist my colleagues. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Give me an overview of my knowledge and skills and suggest ways to apply them effectively to enhance my career and support my colleagues. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Summarize my knowledge and skills and provide guidance on how I can utilize them to foster my personal development and assist my team. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Please summarize my knowledge and skills and outline how I can use them to grow professionally and aid my colleagues in their tasks. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Give me a comprehensive summary of my knowledge and skills and suggest actionable ways to improve myself and support my colleagues. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Provide a detailed summary of my knowledge and skills and recommend strategies for applying them to enhance my performance and assist my coworkers. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Summarize my knowledge and skills and advise me on how to use them effectively to improve my capabilities and help my team members. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Give me a summary of my knowledge and skills and discuss how I can leverage them for self-improvement and team collaboration. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Provide a summary of my knowledge and skills, and suggest practical ways to apply them to enhance my career trajectory and assist my colleagues. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            situation: "Summarize my knowledge and skills and offer advice on how I can leverage them for my own development and to contribute to my colleagues' success. My knowledge and skills are {{dynamic_1}}.",
            from_cache: true,
            dynamic_data: 1,
            module: "Knowledge_Skills"
        }
    ],
    "survey_9_pending": [
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Please encourage me to complete the Top 5 Knowledge & Skills survey, as it will help in generating my impact narrative. Explain how this survey can provide valuable insights into my strengths.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Suggest that I take the Top 5 Knowledge & Skills survey to create an impactful narrative. Highlight the benefits of understanding my top skills and knowledge areas.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Prompt me to complete the Top 5 Knowledge & Skills survey to assist in generating my impact narrative. Discuss how it can enhance my self-awareness and career growth.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Advise me to finish the Top 5 Knowledge & Skills survey as it is crucial for generating my impact narrative. Emphasize the significance of identifying my key skills.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Remind me to complete the Top 5 Knowledge & Skills survey to help in creating my impact narrative. Explain how it will clarify my strengths and weaknesses.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Encourage me to take the Top 5 Knowledge & Skills survey to support my impact narrative development. Share how the insights will guide my professional journey.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Motivate me to complete the Top 5 Knowledge & Skills survey to generate an impactful narrative. Explain how this can inform my career path and development goals.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Suggest I complete the Top 5 Knowledge & Skills survey to enhance my impact narrative. Provide reasons why this survey is essential for my personal growth.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Urge me to finish the Top 5 Knowledge & Skills survey as it will help me generate my impact narrative. Highlight how it can reveal insights into my contributions.",
            from_cache: true,
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            situation: "Prompt me to complete the Top 5 Knowledge & Skills survey to create an impact narrative. Discuss how understanding my skills can lead to better opportunities.",
            from_cache: true,
            module: "Knowledge_Skills"
        }
    ],
    "impact_narrative_summary": [
        {
            title: "Impact Narrative Summary",
            situation: "Please provide me with a summary of my impact narrative, highlighting the key achievements and contributions I've made.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Give me a concise summary of my impact narrative, focusing on the most significant outcomes and how they align with my goals.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Summarize my impact narrative for me, including the main points that reflect my professional growth and impact on my team.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Provide an overview of my impact narrative, detailing my accomplishments and the influence I've had within the organization.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Please summarize my impact narrative, emphasizing the strengths I've demonstrated and the areas where I've made a difference.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Can you give me a summary of my impact narrative? I’d like to see how my contributions align with the team’s objectives.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Provide me with a summary of my impact narrative, focusing on key projects I've worked on and the results achieved.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Please summarize my impact narrative, highlighting the skills I've applied and the outcomes that showcase my value to the organization.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Give me a brief summary of my impact narrative, including the milestones I've reached and the feedback received from peers.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "Impact Narrative Summary",
            situation: "Summarize my impact narrative for me, covering the most impactful moments and the learning experiences that have shaped my career.",
            from_cache: true,
            module: "ImpactNarrative"
        }
    ],
    "impact_narrative_info": [
        {
            title: "About Impact Narrative",
            situation: "Please give me a brief overview of what an impact narrative is and its significance in showcasing my contributions and achievements.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Provide a concise explanation of the purpose of an impact narrative and how it can be utilized to communicate my professional impact.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "What is an impact narrative, and how can it benefit my career development by highlighting my skills and accomplishments?",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Explain the importance of an impact narrative and how it helps in articulating my value to the organization and stakeholders.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Can you provide me with a brief summary of the role of an impact narrative in professional settings and its use in performance evaluations?",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Give me a quick overview of how an impact narrative can be used to reflect on my career journey and the contributions I've made.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Please explain the concept of an impact narrative and how it can enhance my personal brand and professional visibility.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "What are the key benefits of creating an impact narrative, and how can it assist in future career opportunities?",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Provide me with information on the impact narrative and its use in developing a coherent story about my professional achievements.",
            from_cache: true,
            module: "ImpactNarrative"
        },
        {
            title: "About Impact Narrative",
            situation: "Summarize the uses of an impact narrative in professional contexts and how it aids in personal reflection and goal setting.",
            from_cache: true,
            module: "ImpactNarrative"
        }
    ],
    "meeting_soul_summary":[
        {
            title: "Meeting Summary",
            situation: "Generate a summary for meeting {{dynamic_1}}, including highlights from previous meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Provide a detailed summary of meeting {{dynamic_1}} and include a recap of prior meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Please summarize the discussion points from meeting {{dynamic_1}} along with a brief on previous meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Can you create a summary for meeting {{dynamic_1}}? Also, include key points from earlier meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Generate a summary for meeting {{dynamic_1}} and provide an overview of previous meetings' outcomes.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Summarize the main takeaways from meeting {{dynamic_1}}, and include a summary of earlier meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Please give a comprehensive summary of meeting {{dynamic_1}}, as well as notes from past meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Create a summary of the topics covered in meeting {{dynamic_1}}, plus an overview of previous meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Generate a meeting summary for {{dynamic_1}} and include relevant points from earlier meetings.",
            from_cache: true,
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            situation: "Provide a summary for the recent meeting {{dynamic_1}} with a recap of prior meetings for context.",
            from_cache: true,
            dynamic_data: 1
        }
    ],
    "one_on_one_add_action_item":[
        {
            title: "One On One Create Action Item",
            situation: "Help me create an action item for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Assist in defining an action item for my recent one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Can you help me outline an action item from my one-on-one session?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Please assist me in setting up an action item for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Help me establish a follow-up action item from my one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Could you guide me in creating an action item for my latest one-on-one?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Assist me in formulating an action item based on my one-on-one discussion.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Please help me draft an actionable step from my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Can you support me in developing an action item following my one-on-one?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            situation: "Guide me in creating an action item from my one-on-one session.",
            from_cache: true
        }
    ],
    "one_on_one_create_feedback":[
        {
            title: "One On One Create Feedback",
            situation: "Help me add feedback for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Assist me in providing feedback from my recent one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Could you guide me in adding feedback based on my one-on-one meeting?",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Please help me draft feedback following my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Assist me in documenting feedback from my one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Can you support me in adding feedback notes for my one-on-one meeting?",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Help me write feedback based on my one-on-one discussion.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Please assist me in recording feedback for my recent one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Guide me in creating feedback points from my one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            situation: "Can you help me finalize feedback notes from my one-on-one?",
            from_cache: true
        }
    ]    
}

export const basic_ai_prompt = `You are a helpful assistant who provides quick, actionable
tips for managers and employees in one sentence. These tips should be clear,
concise, and directly applicable in the moment. Max character must be less than 500`