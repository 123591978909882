module.exports = {
  PAGINATION_LIMIT: 12,
  REPORT_AVAILABLE_FOR: [1, 2],
  IS_ADMIN_DROPDOWN: false,
  STORY_BANNER_HEIGHT: 250,

  PRIMARY_CLR: '#B0CB1F',
  IMG:
    'https://res.cloudinary.com/brainayan/image/upload/v1629798644/g5vqlqdawohncfcedz44.jpg' ||
    'http://scanivalve.com/wp-content/plugins/lightbox/images/No-image-found.jpg',
  USER_IMG: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',

  // localStorage varaiable
  RECENT_TOKEN: 'recentToken',

  // Env variables
  NODE_ENV: process.env.NODE_ENV,

  BACKEND_URI: process.env.REACT_APP_BACKEND_URI || '',
  TRANSCRIPTION_SOCKET_URI: process.env.REACT_APP_TRANSCRIPTION_SOCKET_URI || '',
  TRANSCRIPTION_SERVER_URI: process.env.REACT_APP_TRANSCRIPTION_SERVER_URI || '',
  BACKEND_WS_URI: process.env.REACT_APP_BACKEND_WS_URI || '',
  IS_MAINTENANCE: process.env.REACT_APP_IS_MAINTENANCE,

  CLOUDINARY_CLOUDNAME: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
  CLOUDINARY_PRESET: process.env.REACT_APP_CLOUDINARY_PRESET,

  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "",

  SOUL_HOME_TOOL_ID: 12,

  SURVEY_WHOPOSHORO: 2,
  SURVEY_DIVERSITY_PROFILE: 4,
  SURVEY_WHOLE_IDENTITY: 13,
  SURVEY_READINESS_LEVEL: 16,

  ROLEGOAL_HOME_TOOL_ID: 17,
  SHOAL_HOME_TOOL_ID: 25,
  GOAL_HOME_TOOL_ID: 18,
  ROLE_HOME_TOOL_ID: 19,
  CAREER_HOME_TOOL_ID: 29,
  OKR_HOME_TOOL_ID: 13, // Old okr
  HIRING_HOME_TOOL_ID: 26,
  ONEONONE_HOME_TOOL_ID: 31,

  OKR_OBJECTIVE: 14,
  KEY_RESULT: 15,
  MILESTONE: 16,
  FEEDBACK_TOOL_ID: 21,
  SURVEY_ID_3CS: 17,
  VALUE_SURVEY_ID: 3,

  FEEDBACK_COMPRESSED_TOOL_ID: 27,
  MANAGE_PERFORMACE_TOOL_ID: 32,
  ONEONONE_ADD_EDIT_TOOL_ID: 33,
  //ReadinessTool

  READINESS_ADD_OR_EDIT_TOOL_ID: 23,
  READINESS_COMPRESSED_TOOL_ID: 28,
  READINESS_REPORT_TOOL_ID: 22,
  FEEDBACK_SURVEY_ID: 'U3VydmV5VHlwZToxNQ==',
  ONE_ON_ONE_TOOL_WHILE_MEETING: 34,


  DIVERSITY_SURVEY_TOOL_ID: 37,
  WHOPOSHORO_SURVEY_TOOL_ID: 10,
  WHOLE_SURVEY_TOOL_ID: 11,

  GROW_ADD_TOOL_ID:38,
  GROW_SURVEY_IDENTIFIER:"R1JPVzo2Mg==",
  GROW_GOAL_QUESTION_ADDRESS:"ZmdkZnlqdGVnaHJl",
  // add "continue" as  question address for the question that we only want to show in contine conversation
  
  BARS_HOME_TOOL_ID: 39,
  BARS_KNOW_MORE: 40,
  COMPETENCY_IMPORT_PERMISSION:['add_competency'],
  COMPETENCY_ADD_PERMISSION:['add_competency'],
  COMPETENCY_VIEW_PERMISSION:['view_competency'],
  COMPETENCY_DELETE_PERMISSION:['delete_competency'],
  COMPETENCY_UPDATE_PERMISSION:['change_competency'],

  EMPLOYEE_LEVEL_ADD_PERMISSION:['add_employeelevel'],
  EMPLOYEE_LEVEL_VIEW_PERMISSION:['view_employeelevel'],
  EMPLOYEE_LEVEL_DELETE_PERMISSION:['delete_employeelevel'],
  EMPLOYEE_LEVEL_UPDATE_PERMISSION:['change_employeelevel'],

  JOB_FAMILY_ADD_PERMISSION:['add_jobfamily'],
  JOB_FAMILY_VIEW_PERMISSION:['view_jobfamily'],
  JOB_FAMILY_DELETE_PERMISSION:['delete_jobfamily'],
  JOB_FAMILY_UPDATE_PERMISSION:['change_jobfamily'],

  BARS_ADD_PERMISSION:['add_behaviourallyanchoredrating'],
  BARS_VIEW_PERMISSION:['view_behaviourallyanchoredrating'],
  BARS_DELETE_PERMISSION:['delete_behaviourallyanchoredrating'],
  BARS_UPDATE_PERMISSION:['change_behaviourallyanchoredrating'],
  
  ROLE_ADD_PERMISSION:['add_role'],
  ROLE_VIEW_PERMISSION:['view_role'],
  ROLE_DELETE_PERMISSION:['delete_role'],
  ROLE_UPDATE_PERMISSION:['change_role'],

  QUALIFYING_LEVEL_ADD_PERMISSION:['add_qualifyinglevel'],
  QUALIFYING_LEVEL_VIEW_PERMISSION:['view_qualifyinglevel'],
  QUALIFYING_LEVEL_DELETE_PERMISSION:['delete_qualifyinglevel'],
  QUALIFYING_LEVEL_UPDATE_PERMISSION:['change_qualifyinglevel'],

  ROLE_DEFENITION_HOME_TOOL_ID:41,

  NEW_OKR_TOOL_ID:13,
  NEW_OKR_DISCARD_TOOL_ID: 13,


  //Soul survey id sets

  VALUE_SUERVEY_PART_1:3,
  VALUE_SUERVEY_PART_2:5,
  PERSONALITY_PART_1:8,
  PERSONALITY_PART_2:10,
  STRENGTH_PART_1:7,
  STRENGTH_PART_2:6,
  KNOWLEDGE_PART_1:9,
  KNOWLEDGE_PART_2:11,

  BADGE_FAMILY_VIEW_PERMISSION:['view_badgefamily'],
  BADGE_FAMILY_ADD_PERMISSION:['view_badgefamily','add_badgefamily'],
  BADGE_FAMILY_UPDATE_PERMISSION:['view_badgefamily','change_badgefamily'],
  BADGE_FAMILY_DELETE_PERMISSION:['view_badgefamily','delete_badgefamily'],

  BADGE_VIEW_PERMISSION:['view_badge'],
  BADGE_ADD_PERMISSION:['view_badgefamily','add_badge'],
  BADGE_UPDATE_PERMISSION:['view_badgefamily','change_badge'],
  BADGE_DELETE_PERMISSION:['view_badgefamily','delete_badge'],

  BADGE_LEVEL_VIEW_PERMISSION:['view_badgelevel'],
  BADGE_LEVEL_ADD_PERMISSION:['view_badgefamily','add_badgelevel'],
  BADGE_LEVEL_UPDATE_PERMISSION:['view_badgefamily','change_badgelevel'],
  BADGE_LEVEL_DELETE_PERMISSION:['view_badgefamily','delete_badgelevel'],


  BADGE_AWARD_ADD_PERMISSION:['add_badgeaward','view_badgeaward'],
  BADGE_AWARD_VIEW_PERMISSION:['view_badgeaward'],
  BADGE_AWAED_ADD_USER_PERMISSION:['add_badgeaward'],
  BADGE_AWAED_DELETE_USER_PERMISSION:['delete_badgeaward'],


  VIEW_OKR:['view_okr'],
  VIEW_FEEDBACK:['view_feedback'],
  VIEW_MEETING:['view_meeting'],

  USER_MANUAL_SURVEY_ID : 26,
  transcript_grouping_seconds:20,
  transcript_initiator:'transcript_initiator',
  transcript_terminate:'transcript_terminate',
  transcript_sync_started:'transcript_sync_started',
  transcript_sync_confirmed:'transcript_sync_confirmed',

  COMPETENCY_FILE_UPLOAD_URL_ENABE:false,

  SOUL_SURVEY_RESPONSE_TOOLS:[3,7,8,9],
  SOUL_GENERIC_TOOLS:[2,3,5,4,6],
  WHOLE_GENERIC_TOOL_ID:11,
  DIVERSITY_PROFILE_IDENTIFIER:"RGl2ZXJzaXR5IFByb2ZpbGU",
  WHOPOSHORO_ASSESSMENT_SURVEY_IDENTIFIER:"V2hvUG9TaG9SbyBhc3Nlc3NtZW50",
  WHOLE_IDENTITY_ASSESSMENT_IDENTIFIER:"V2hvbGUgSWRlbnRpdHkgQXNzZXNzbWVudA",
  THREE_C_IDENTIFIER:"M0NzIFN1cnZleQ",
  USER_MANUAL_SURVEY_IDENTIFIER:"USER_MANUAL",
  CALL_SERVER_CONFIG:{
    stun_url:process.env.REACT_APP_CALL_STUN_SERVER,
    turn_url:process.env.REACT_APP_CALL_TURN_SERVER,
    turn_user:process.env.REACT_APP_CALL_TURN_USER,
    turn_password:process.env.REACT_APP_CALL_TURN_PASSWORD,
  },
  audio_auto_play_timer:5000, //5 seconds
  call_reconnection_timer:45000, // 45 seconds

  ACCESS_TOKEN:'accessToken',
  REFRESH_TOKEN:'refreshToken',
  JWT_AUTHENTICATION:'Authorization',
  diversity_survey_id:4,
  request_impact_narrative: process.env.REACT_APP_REQUEST_IMPACT_NARRATIVE || '',
  impact_narrative_socket:process.env.REACT_APP_IMPACT_NARRATIVE_SOCKET || '' ,

// new dashboard config
  diversity_questions_for_in:{
    gender:36,
    HighestDegree:38,
    DiplomasCertificates:659,
    SubjectsOfInterest:660,
    YearsOfExperience:662,
    CurrentNationality:665,
    Specializations:645,
  },
  personality_questions:[624, 625, 626, 627, 628],
  available_personality:['ISTJ', 'ISFJ', 'INFJ', 'INTJ', 'ISTP', 'ISFP', 'INFP', 'INTP', 'ESTP', 'ESFP', 'ENFP', 'ENTP', 'ESTJ', 'ESFJ', 'ENFJ', 'ENTJ'],
  grow_survey_id:21,
  feedback_survey_id:15,
  report_tool_data:[
    {
      name:"WHOPOSHORO",
      survey_id:2,
      tool_id:10
    },
    {
      name:"Whole Identity Assessment",
      survey_id:13,
      tool_id:11
    },
    {
      name:"3CS",
      survey_id:17,
      tool_id:24
    }    
  ],
  Diversity_question_ids:{
    diversity_survey_generation_id:39,
    race:44,
    age:661,
    generation_options:[
      {
        id:121,
        min_year:1946,
        max_year:1964
      },
      {
        id:122,
        min_year:1965,
        max_year:1980
      },
      {
        id:123,
        min_year:1981,
        max_year:1996
      },
      {
        id:124,
        min_year:1997,
        max_year:2012
      },
      {
        id:125,
        min_year:2013,
        max_year:2025
      }
    ]
  },
  feedback_1_1_survey_id:20,
  effectiveness_indicator_one_on_one:23,
  readiness_demo_video_url: null,
  report_tool_3cs:24,
  question_id_for_3cs:692,
  impact_narrative_cookie:"dev-impact-narrative-status",
  impact_narrative_cookie_expire:5,
  impact_generation_status:"impact_generation_status",
  bulk_invitation_limit:10, 
  learning_path_time_limit:30,
  cc_graduation_test:[32,33,34],
  disable_360_delete_action: [17],
};
