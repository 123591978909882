import React from 'react';
import IDPDetailView from '../components/idpDetailView';
import { getIdpBase64StringFromInt, getIntFromString, globalPermissionValidator, replaceAITipDynamicContent } from 'modules/look';
import { IDP_DETAIL_BY_ID } from '../graphql/idpDetail.gql';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { idp_status } from '../idp.config';
import { withUpdateIdp } from '../idpOperations';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd';
import moment from 'moment';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';

const IDPDetailComtainer = (props) => {
    const { match, client, updateIdp, userPermission,me } = props
    const [idp_detail, setIdpDetail] = React.useState()
    const [idp_detail_loading, setIdpLoading] = React.useState(false)
    const [permission, setPermission] = React.useState()
    const [ai_trigger,SetAITrigger] = React.useState()
    
    //Permission Check
    React.useEffect(() => {

        localStorage.removeItem('emailRoute')

        if (userPermission?.length) {
            let permission = globalPermissionValidator(idp_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (match?.params && permission?.required_permission && me) {
            let id = getIdpBase64StringFromInt(match?.params?.idp_id)
            getIdpById(id)
        }
    }, [match, permission,me])

    const getIdpById = async (id) => {
        setIdpLoading(true)
        const { data } = await client.query({
            query: IDP_DETAIL_BY_ID,
            variables: { id: id },
            fetchPolicy: 'network-only'
        });
        if (data?.idpById) {
            setIdpDetail(data?.idpById)
            let idp = data?.idpById 
            let date_gap = moment(idp?.duedate).endOf("day").diff(moment().endOf("day"),"day",true)
            if(getIntFromString(idp?.employee?.user?.id) == getIntFromString(me?.id)){
                if(moment(idp?.duedate).isBefore(moment())){
                    SetAITrigger({
                        trigger:ai_trigger_content.idp_due_date_pass,
                    })
                }else if(date_gap>=0 && date_gap<=2 && idp?.status != idp_status?.Completed?.for_query && idp?.status != idp_status?.Draft?.for_query){
                    SetAITrigger({
                        trigger:ai_trigger_content.idp_due_date_soon,
                        dynamic_data:[idp?.goal]
                    })
                }
            }
        }
        setIdpLoading(false)
    }

    const OnSubmitIDP = async (data) => {
        try {
            setIdpLoading(true)
            if(data?.status){
                if(data?.status == idp_status?.ToDo.label){
                    data.status = idp_status?.ToDo.for_mutation
                }
                if(data?.status == idp_status?.InProgress.label){
                    data.status = idp_status?.InProgress.for_mutation
                }
            }
            let update = { ...data, id: getIntFromString(idp_detail?.id) }
            delete update?.approverId
            delete update?.sendTo
            const response = await updateIdp(update)
            if (response?.id) {
                getIdpById(idp_detail?.id)
            } else {
                setIdpLoading(false)
            }

        } catch (error) {
            setIdpLoading(false)
        }
    }

    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <IDPDetailView
                        idp_detail={idp_detail}
                        idp_detail_loading={idp_detail_loading}
                        OnSubmitIDP={OnSubmitIDP}
                        ai_trigger={ai_trigger}
                        SetAITrigger={SetAITrigger}
                        permission={permission}
                        {...props} />
                )
            }

            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )
}
export default compose(withApollo, withUpdateIdp)(IDPDetailComtainer)