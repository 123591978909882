// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import OneonOneHomeView from '../components/OneOnoneHomeView'
import NotFound from '../../page/containers/NotFound'
import { withToolById } from 'modules/tool/containers/ToolOperations';
import { useHistory } from 'react-router-dom';
import { globalPermissionValidator } from 'modules/look';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent'; 
const OneonOneHomeContainer = props => {
  const { userPermission } = props
  const history = useHistory();
  const typeList = ['upcoming', '1_1', 'future']
  let type = props.match.params.type;
  const [permission, setpermission] = React.useState()

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])

  if (!typeList.includes(type)) {
    return (
      <>
        <NotFound></NotFound>
      </>
    )
  }
  else {
    return (
      <>
        {
          (permission && permission?.requried_permission) &&(
            <OneonOneHomeView {...props} type={type} history={history} permission={permission}></OneonOneHomeView>
          )
        }

        {
          (permission && !permission?.requried_permission)&&(
            <NoPermissionView/>
          )
        } 
      </>
    );
  }


};

export default compose()(OneonOneHomeContainer);
